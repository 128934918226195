// Import necessary React hooks and components from Material-UI and other modules
import React, { useCallback, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import {
  Grid,
  Paper,
  Button as MuiButton,
  Autocomplete,
  createFilterOptions,
} from "@mui/material";

import { withRouter } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import CircularProgress from "@mui/material/CircularProgress";
import AssessmentIcon from "@mui/icons-material/Assessment";

// Import custom components and actions from Redux
import Menu from "../../../core/Menu";
import PageHeader from "../../../core/PageHeader";
import { Form } from "../../../core/useForm";
import Input from "../../../core/controls/Input";
import Datepicker from "../../../core/controls/Datepicker";
import Button from "../../../core/controls/Button";
import { useEffect } from "react";

import { getProductsList, getCustomersList } from "../../../redux/actions/CurrentStockReportActions";
import Notification from "../../../core/Notification";

import { API_URL } from "../../../redux/constants/apiConstants";
import Popup from "../../../core/Popup";

import axios from "axios";

import { exportToExcelExcelJs } from "../../../utils/json-to-excel-export-exceljs";

// Define Material-UI styles using makeStyles hook
const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: useTheme().spacing(1),
    padding: useTheme().spacing(2),
    [useTheme().breakpoints.up("md")]: {
      margin: useTheme().spacing(5),
      padding: useTheme().spacing(3),
    },
  },
  adornmentText: {
    "& .MuiTypography-root": {
      color: "#f3b33d",
      fontWeight: "bolder",
      fontSize: "1.5rem",
    },
  },
  submitButtonGroup: {
    backgroundColor: "#192A53",
    color: "#fff",
    margin: useTheme().spacing(1),
    "& .MuiButtonBase-root": {
      textTransform: "none",
      color: "#fff",
      border: "2px solid #5C636A",
    },
    "&:hover": {
      backgroundColor: " #192A53",
    },
  },
}));

// Get current date to set initial values for the date picker
var date = new Date(),
  mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  day = ("0" + date.getDate()).slice(-2);

// Define initial form values
const initialValues = {
  from: [date.getFullYear(), mnth, day].join("-"),
  to: [date.getFullYear(), mnth, day].join("-"),
  productName: {
    id: "",
    label: "",
  },
  customerName: {
    id: "",
    label: "",
  },
};

// Define the main component
const ProdctOutReport = ({ history }) => {
  // Initialize Redux dispatch
  const dispatch = useDispatch();

  // Initialize Material-UI styles
  const classes = useStyles();

  // Initialize useRef for CSV download
  const csvDownloadRef = useRef(0);

  // Initialize state for notifications
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  // Initialize state for checkbox
  const [checked, setChecked] = useState(true);

  // Initialize state for pop-up
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  // Redux selectors for getting product and customer lists
  const productsNameList = useSelector((state) => state.productNameList);
  const {
    loading: loadingProductsName,
    error: errorProductsName,
    productNameList,
  } = productsNameList;

  const customerNameListState = useSelector((state) => state.customerNameListState);
  const {
    loading: loadingCustomersName,
    error: errorCustomersName,
    customerNameList,
  } = customerNameListState;

  // Redux selector for getting user login information
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // Define heading structure for the excel export
  const heading_with_title = {
    soSystemId: "System Id",
    soSystemNo: "Invoice No",
    invoiceDate: "Invoice Date",
    soCustomerId: "Customer Id",
    customerName: "Customer Name",
    customerAddress: "Customer Address",
    jobType: "Type Of Sales",
    sodProductId: "Product Id",
    productName: "Product Name",
    sodConfirmedQty: "Sold Quantity",
    sodConfirmedUnitPrice: "Unit Price",
    soCurrentStatus: "Work Pending Id",
    lifecycleName: "Work Pending Name",
    soAssociateCurrentStatus: "Decession Id",
    decessionPending: "Pending Name",
    soRemarks:"Remarks",
    soCustomerReferance:"Customer Ref",
    soRelatedLcId:"LC Ref",
    soVatRefNo:"Vat Ref",
    soVatSystemInvoiceNo:"Vat System Invoice"

  };

  // Define field values for the excel export
  const heading_field_values = [
    "soSystemId",
    "soSystemNo",
    "invoiceDate",
    "soCustomerId",
    "customerName",
    "customerAddress",
    "jobType",
    "sodProductId",
    "productName",
    "sodConfirmedQty",
    "sodConfirmedUnitPrice",
    "soCurrentStatus",
    "lifecycleName",
    "soAssociateCurrentStatus",
    "decessionPending",
  ];

  // Define formik form handling
  const formik = useFormik({
    initialValues,
    onSubmit: async (values, action) => {
      // Set up Axios configuration for API call
      const axios_config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
      };

      // Construct API endpoint URL
      const api_endpoint = `${API_URL}/Report/GetProductOutReport?startDate=${values?.from}&endDate=${values?.to}&productId=${values?.productName?.id !== "" ? values?.productName?.id : "0"}&customerId=${values?.customerName?.id !== "" ? values?.customerName?.id : "0"}`;

      // Make API call using Axios
      const response = await axios.get(api_endpoint, axios_config);
      const { data } = response;

      // Check for errors in the API response
      if (response?.errMsg) {
        // Handle errors if any
      } else {
        // Check if data is present in the response
        if (data?.dataObj?.length > 0) {
          // Initialize an array to store the updated data
          let updatedData = [];

          // Iterate through the data and create a new serialized object
          for (const value of data?.dataObj) {
            const newSerializedObject = {
              soSystemId: value?.soSystemId,
              soSystemNo: value?.soSystemNo,
              invoiceDate: value?.invoiceDate,
              soCustomerId: value?.soCustomerId,
              customerName: value?.customerName,
              customerAddress: value?.customerAddress,
              jobType: value?.jobType,
              sodProductId: value?.sodProductId,
              productName: value?.productName,
              sodConfirmedQty: value?.sodConfirmedQty,
              sodConfirmedUnitPrice: value?.sodConfirmedUnitPrice,
              soCurrentStatus: value?.soCurrentStatus,
              lifecycleName: value?.lifecycleName,
              soAssociateCurrentStatus: value?.soAssociateCurrentStatus,
              decessionPending: value?.decessionPending,
              soRemarks:value?.soRemarks,
              soCustomerReferance:value?.soCustomerReferance,
              soRelatedLcId:value?.soRelatedLcId,
              soVatRefNo:value?.soVatRefNo,
              soVatSystemInvoiceNo:value?.soVatSystemInvoiceNo
            };
            // Push the new object to the array
            updatedData.push(newSerializedObject);
          }

          // Call the utility function to export data to Excel
          exportToExcelExcelJs(
            updatedData,
            heading_with_title,
            `Product_Stock_Out_Report_Data_${
              values?.productName?.id !== ""
                ? data?.dataObj[0]?.productName
                : "All_Products"
            }(${values.from}_to_${values.to})`,
            `Report For ${
              values?.productName?.id !== ""
                ? data?.dataObj[0]?.productName
                : "All Product"
            } (${values.from} to ${values.to})`
          );
        } else {
          // If no data is found, show a notification
          action.setSubmitting(false);
          setOpenPopup({
            ...openPopup,
            title: "ZERO DATA!!!",
            subTitle: "No Data found with this specifications!",
            isOpen: true,
          });
        }
      }
    },
  });

  // Fetch product and customer lists on component mount
  useEffect(() => {
    if (userInfo) {
      dispatch(getProductsList());
      dispatch(getCustomersList());
    } else {
      // Redirect to login if user information is not available
      const location = {
        pathname: "/signin",
        state: { from: { pathname: "/productout" } },
      };
      history.push(location);
    }
  }, [dispatch, history, userInfo]);

  // Create filter options for Autocomplete
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 100,
  });

  // Handle product name change in Autocomplete
  const productNameChange = useCallback((e, v) => {
    formik.setFieldValue("productName", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  // Handle customer name change in Autocomplete
  const customerNameChange = useCallback((e, v) => {
    formik.setFieldValue("customerName", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  // Render the main component structure
  return (
    <div>
      {/* Render the Menu component */}
      <Menu />
      {/* Render the PageHeader component with relevant information */}
      <PageHeader
        icon={<AssessmentIcon />}
        title="Product Out Report"
        subtitle="Generate Report"
      />
      {/* Render the main content inside a Paper component */}
      <Paper className={classes.pageContent}>
        {/* Render the form inside a Form component */}
        <Form onSubmit={formik.handleSubmit}>
          {/* Create a grid container */}
          <Grid container>
            {/* Grid item for date selection */}
            <Grid item xs={12} md={6}>
              <Datepicker
                label="From"
                name="from"
                value={formik.values.from}
                onChange={formik.handleChange}
              />
              <Datepicker
                label="To"
                name="to"
                value={formik.values.to}
                onChange={formik.handleChange}
              />
            </Grid>
            {/* Grid item for Autocomplete components */}
            <Grid item xs={12} md={6}>
              {/* Autocomplete for Product Name */}
              <Autocomplete
                size="small"
                disabled={loadingProductsName ? true : false}
                id="combo-box-demo-channel"
                onChange={productNameChange}
                value={formik.values.productName}
                options={productNameList !== undefined ? productNameList : []}
                onOpen={formik.handleBlur}
                filterOptions={filterOptions}
                renderInput={(params) => (
                  <Input
                    label="Product Name"
                    name="customer"
                    error={
                      formik.errors.customer !== undefined
                        ? formik.errors.customer.id
                        : ""
                    }
                    touched={
                      formik.touched.customer !== undefined
                        ? formik.touched.customer
                        : ""
                    }
                    {...params}
                    onBlur={formik.handleBlur}
                  />
                )}
              />
              {/* Autocomplete for Customer Name */}
              <Autocomplete
                size="small"
                disabled={loadingCustomersName ? true : false}
                id="combo-box-demo-channel"
                onChange={customerNameChange}
                value={formik.values.customerName}
                options={customerNameList !== undefined ? customerNameList : []}
                onOpen={formik.handleBlur}
                filterOptions={filterOptions}
                renderInput={(params) => (
                  <Input
                    label="Customer Name"
                    name="customer"
                    error={
                      formik.errors.customer !== undefined
                        ? formik.errors.customer.id
                        : ""
                    }
                    touched={
                      formik.touched.customer !== undefined
                        ? formik.touched.customer
                        : ""
                    }
                    {...params}
                    onBlur={formik.handleBlur}
                  />
                )}
              />
            </Grid>
            {/* Grid item for buttons */}
            <Grid item xs={12} md={12}>
              {/* Submit button with loading indicator */}
              <MuiButton
                className={classes.submitButtonGroup}
                endIcon={
                  loadingProductsName ? (
                    <CircularProgress size="1rem" color="error" />
                  ) : null
                }
                size="large"
                type="submit"
              >
                {/* Excel icon for the button */}
                <img
                  width={40}
                  height={40}
                  src={process.env.PUBLIC_URL + "/images/excel-icon.png"}
                  alt=""
                />
              </MuiButton>
              {/* Back button */}
              <Button
                color="error"
                text="Back"
                onClick={() => {
                  const location = {
                    pathname: "/",
                    state: {},
                  };
                  history.push(location);
                }}
              />
            </Grid>
          </Grid>
        </Form>
      </Paper>
      {/* Notification component for displaying messages */}
      <Notification notify={notify} setNotify={setNotify} />
      {/* Popup component for displaying pop-ups */}
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />
    </div>
  );
};

// Export the component with withRouter for access to history object
export default withRouter(ProdctOutReport);
