import { makeStyles, useTheme } from "@material-ui/core/styles";
export const useStyles = makeStyles(() => ({

  
    actions: {
      display: "flex",
      justifyContent: "flex-start !important",
      width: "500px",
      padding: "0px !important",
      [useTheme().breakpoints.up("md")]: {
        display: "flex",
        justifyContent: "flex-end !important",
        width: "420px",
      },
    },
    colHeader: {
      height: 550,
  
      [useTheme().breakpoints.up("md")]: {
        maxWidth: "2000px",
        margin: "0 auto",
        padding: "0 60px",
      },
  
      "& .MuiDataGrid-root": {
        border: "none",
        overflowX: "auto",
      },
  
      "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows": {
        margin: "auto",
      },
  
      "& .MuiDataGrid-columnHeaders": {
        backgroundColor: "#6C4AB6",
        color: "#ffff",
        borderBottom: "none",
      },
  
      "& .MuiDataGrid-columnHeader": {
        minWidth: "250px !important",
        maxWidth: "250px !important",
      },
  
      "& .MuiDataGrid-cell": {
        minWidth: "250px !important",
        maxWidth: "250px !important",
        borderBottom: "none",
        textAlign: "center",
      },
  
      "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within ": {
        outline: "none !important",
      },
  
      "& .name-column--cell": {
        color: "#6C4AB6",
      },
      "& .MuiDataGrid-cell:last-child": {
        [useTheme().breakpoints.up("md")]: {
          minWidth: "0px !important",
          maxWidth: "0px !important",
        },
      },
  
      "& .MuiDataGrid-virtualScroller": {
        width: "2090px",
        overflowX: "scroll !important",
      },
  
      "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
        height: "6px",
        width: "6px",
        backgroundColor: "#F5F5F5",
      },
  
      "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
        backgroundColor: "#B9B9B9",
      },
  
      "& .MuiDataGrid-columnHeaderCheckbox, .MuiDataGrid-cellCheckbox": {
        minWidth: "60px !important",
        maxWidth: "60px !important",
      },
      "& .MuiDataGrid-columnHeader[data-field='paymentSystemId'], .MuiDataGrid-cell[data-field='paymentSystemId']":
        {
          minWidth: "60px !important",
          maxWidth: "60px !important",
        },
  
      "& .MuiDataGrid-footerContainer": {
        borderTop: "none",
        backgroundColor: "#6C4AB6",
        color: "#fff",
      },
      "& .MuiDataGrid-footerContainer .MuiTablePagination-root": {
        color: "#fff",
      },
      "& .MuiDataGrid-footerContainer .MuiTablePagination-root .MuiSvgIcon-root":
        {
          color: "#fff",
        },
  
      "& .MuiButtonBase-root .MuiCheckbox-root": {
        backgroundColor: "#A4A9FC !important",
        color: "#b7ebde !important",
      },
    },
  
    toolBar: {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      paddingRight: "0px !important",
      paddingLeft: "0px !important",
      [useTheme().breakpoints.up("md")]: {
        maxWidth: "2000px !important",
        margin: "0 auto !important",
        padding: "0 60px !important",
      },
      "& .MuiButtonBase-root": {
        [useTheme().breakpoints.down("md")]: {
          width: "30% !important",
          fontSize: "smaller !important",
        },
      },
      //
    },
    wrapperWhite: {
      backgroundColor: "#fff",
      padding: "2px",
    },
  }));