import React from "react";
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport } from "@mui/x-data-grid";
import { Stack, IconButton } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const CustomToolbar = ({ onAdd, onEdit, onDelete, disableEdit, disableDelete }) => {
  return (
    <GridToolbarContainer
      style={{
        backgroundColor: "#f0f0f0", // Light gray background color
        padding: "8px 16px", // Add some padding for better spacing
        borderRadius: "4px 4px 0 0" // Rounded corners on top to match DataGrid style
      }}
    >
      <Stack direction="row" justifyContent="space-between" width="100%" spacing={1}>
        
        {/* Custom action buttons aligned to the left */}
        <Stack direction="row" spacing={1}>
          <IconButton color="primary" onClick={onAdd}>
            <AddCircleOutlineIcon />
          </IconButton>
          <IconButton color="secondary" onClick={onEdit} disabled={disableEdit}>
            <EditOutlinedIcon />
          </IconButton>
          <IconButton color="error" onClick={onDelete} disabled={disableDelete}>
            <DeleteOutlineIcon />
          </IconButton>
        </Stack>

        {/* Default DataGrid toolbar controls aligned to the right */}
        <Stack direction="row" spacing={1}>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
          <GridToolbarExport />
        </Stack>

      </Stack>
    </GridToolbarContainer>
  );
};
export default CustomToolbar;
