import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Decoder from "jwt-decode";
import { v4 as uuidv4 } from "uuid";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import { Container } from "react-bootstrap";
import Menu from "../../core/Menu";
import {
  FilterProductsForInvoice,
  listProductsForInvoice,
} from "../../redux/actions/invoiceActions";
import { Typeahead } from "react-bootstrap-typeahead";
import Popup from "../../core/Popup";
import SuccessPopup from "../../core/SuccessPopup";
import { CircularProgress } from "@mui/material";
import StockAdjustmentItem from "../../core/StockAdjustmentItem";
import {getChannelsList,getCurrentStock,
} from "../../redux/actions/CurrentStockReportActions";
import { GET_CURRENT_STOCK_RESET } from "../../redux/constants/CurrentStockReportConstants";
import { adjustStockin } from "../../redux/actions/stockInActions";

var FilteredProduct = [];
var date = new Date();
var d = date.toString();
d = d.split(" ");
var d = [d[2], d[1], d[3]].join("-");

const CreatestockAdjustment = ({ history }) => {
  const dispatch = useDispatch();

  // Selectors for Redux state
  const { loading: loadingChannelsName, error: errorChannelsName, channelNameList } =
    useSelector((state) => state.getChannelNameList);

  const { loading: loadingProduct, error: errorProduct, products } =
    useSelector((state) => state.invoiceProductList);

  const { loading: loadingCreate, error: errorCreate, success: successCreate, adjustedStockin } =
    useSelector((state) => state.stockinAdjust);

  const { userInfo } = useSelector((state) => state.userLogin);
  const decodedata = userInfo ? Decoder(userInfo.token) : null;

  // State for UI and form management
  const [checked, setChecked] = useState(true);
  const [switchTStuck, setSwitchTStuck] = useState(false);
  const [mappingOption, setMappingOption] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState([]);
  const [selected, setSelected] = useState([]);

  // Product state
  const [product, setProduct] = useState({
    id: "",
    label: "",
    quantity: "",
    currentStock: "",
  });

  // Popup state
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [openPopup2, setOpenPopup2] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  // Data for stock adjustment
  const [data, setData] = useState({
    isOpen: false,
    soCustomerReferance: "",
    currentDate: "",
    dateOfIssue: new Date().toLocaleDateString(),
    notes: "",
    items: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      if (userInfo) {
        try {
          // Fetch channels and products if the user is logged in
          await dispatch(getChannelsList());
          await dispatch(listProductsForInvoice());
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
        // Redirect to sign-in if the user is not logged in
        history.push({
          pathname: "/signin",
          state: { from: { pathname: "/stockin/stockadjustment" } },
        });
      }
    };
  
    fetchData();
  }, [dispatch, history, userInfo]);
  
  const handleRowDel = async (items) => {
    try {
      // Filter out the deleted item from the product list
      FilteredProduct = FilteredProduct.filter((item) => item !== items.productId);
  
      // Dispatch updated filtered product list
      await dispatch(FilterProductsForInvoice(FilteredProduct));
  
      // Remove the deleted item from the data.items array
      const updatedItems = data.items.filter((item) => item !== items);
  
      // Update the state
      setData({
        ...data,
        items: updatedItems,
      });
    } catch (error) {
      console.error("Error deleting row:", error);
    }
  };
  const handleAddEvent = (evt) => {
    const id = generateUniqueId();
  
    if (isValidForm()) {
      setSwitchTStuck(true);
  
      // Create new item
      const newItem = {
        productId: product.id,
        productName: product.label,
        quantity: parseFloat(product.quantity), // Supports negative values
        currentStock: parseFloat(product.currentStock) || 0,
      };
  
      // Update data.items immutably
      const updatedItems = [...data.items, newItem];
      setData((prevState) => ({
        ...prevState,
        items: updatedItems,
      }));
  
      // Reset product state
      resetProductState();
  
      // Update filtered products and reset state
      FilteredProduct.push(product.id);
      dispatch(FilterProductsForInvoice(FilteredProduct));
      setSelected([]);
      dispatch({ type: GET_CURRENT_STOCK_RESET });
    } else {
      showEmptyFieldsPopup();
    }
  };
  
  // Helper to generate unique ID
  const generateUniqueId = () =>
    (+new Date() + Math.floor(Math.random() * 999999)).toString(36);
  
  // Helper to validate the form
  const isValidForm = () =>
    data.soCustomerReferance !== "" &&
    product.id !== "" &&
    product.currentStock >= 0 &&
    product.quantity !== "" &&
    selectedChannel.length > 0;
  
  // Helper to reset product state
  const resetProductState = () => {
    setProduct({
      id: "",
      label: "",
      quantity: "",
      currentStock: "",
    });
  };
  
  // Helper to show popup for empty fields
  const showEmptyFieldsPopup = () => {
    setOpenPopup({
      ...openPopup,
      title: "EMPTY FIELD(S)!!!",
      subTitle: "One or more fields are Empty!",
      isOpen: true,
    });
  };
  const onItemizedItemEdit = (evt) => {
    var item = {
      id: evt.target.id,
      name: evt.target.name,
      value: evt.target.value,
    };
    var items = data.items.slice();
    var newItems = items.map(function (sitems) {
      for (var key in sitems) {
        if (key == item.name && sitems.productId == item.id) {
          sitems[key] = item.value;
        }
      }
      return sitems;
    });

    setData({
      ...data,
      items: newItems,
    });
  };
  const editField = (event) => {
    setData({
      ...data,
      [event.target.name]:
        event.target.value !== "" ? Math.abs(event.target.value) : "",
    });
  };

  const editProductField = (event) => {
    setProduct((prevState) => ({
      ...prevState,
      [event.target.name]:
        event.target.value !== "" ? Math.abs(event.target.value) : "",
    }));
  };

  const openModal = (event) => {
    event.preventDefault();

    if (data.items.length > 0) {
      var dataArray = data.items.map((singleData) => {
        return {
          productId: parseInt(singleData.productId),

          productQty: parseFloat(singleData.quantity),
        };
      });

      var data1 = {
        channelId: parseInt(selectedChannel[0].id),
        stockAdjutmentRemark: data.notes !== "" ? data.notes : null,
        saDatetime: new Date(d),
        saReferanceNo:
          data.soCustomerReferance !== "" ? data.soCustomerReferance : null,

        stockAdjutmentProducts: dataArray,
      };

      dispatch(adjustStockin(data1)).then((result) => {
        if (result?.title === "One or more validation errors occurred.") {
          setOpenPopup({
            ...openPopup,
            title: "ERROR!!!",
            subTitle: "Something went wrong!",
            isOpen: true,
          });
        } else if (result?.errMsg) {
          setOpenPopup({
            ...openPopup,
            title: "ERROR!!!",
            subTitle: `${result?.errMsg}`,
            isOpen: true,
          });
        } else {
          if (checked === true) {
            setOpenPopup2({
              ...openPopup2,
              title: `SUCCESS!!!`,
              subTitle: `Stock Adjusted.`,
              isOpen: true,
            });
            FilteredProduct = [];
            dispatch(FilterProductsForInvoice(FilteredProduct));

            setSwitchTStuck(false);
            setProduct({
              ...product,
              id: "",
              label: "",
              quantity: "",
              currentStock: "",
            });

            setSelected([]);
            setSelectedChannel([]);

            dispatch({ type: GET_CURRENT_STOCK_RESET });
            // setSelectedOrderType([]);
            setOpenPopup({
              ...openPopup,
              isOpen: false,
              title: "",
              subTitle: "",
            });

            setData({
              ...data,
              isOpen: false,
              soCustomerReferance: "",
              currentDate: "",
              dateOfIssue: new Date().toLocaleDateString(),

              notes: "",

              items: [],
            });
          } else {
            history.push("/salesorderinternal");
          }
        }
      });
    } else {
      setOpenPopup({
        ...openPopup,
        title: "ADDITION ISSUE!!!",
        subTitle: "No products added!",
        isOpen: true,
      });
    }
  };
  const closeModal = (event) => setData({ ...data, isOpen: false });

  const handleKeyDown = (event, callback) => {
    if (event.key === "Enter" && event.shiftKey === false) {
      event.preventDefault();
      callback(event);
    }
  };

  return (
    <div>
      <Menu />
      <Container>
        <Form>
          <Row style={{ fontSize: "12px" }}>
            <Col
              md={8}
              lg={12}
              style={{
                transition: "all 0.5s ease-out",
              }}
            >
              <Card className="p-4 p-xl-5 my-3 my-xl-4">
                <Row className="mb-2">
                  <Col>
                    <Form.Group controlId="form-customerId">
                      <Form.Label className="fw-bold">Reference No:</Form.Label>

                      <Form.Control
                        name="soCustomerReferance"
                        type="text"
                        value={data.soCustomerReferance}
                        onChange={(event) => {
                          setData({
                            ...data,
                            soCustomerReferance: event.target.value,
                          });
                        }}
                        disabled={
                          data.items.length > 0 && switchTStuck ? true : false
                        }
                        placeholder="Reference No"
                      />
                    </Form.Group>
                    <Form.Group controlId="form-customerId">
                      <Form.Label className="fw-bold">Channel:</Form.Label>
                      <Typeahead
                        clearButton
                        id="basic-example"
                        name="channelId"
                        label="Channel"
                        disabled={
                          loadingChannelsName
                            ? true
                            : data.items.length > 0 && switchTStuck
                            ? true
                            : false
                        }
                        onChange={(selectedChannel) => {
                          setSelectedChannel(selectedChannel);
                        }}
                        options={
                          channelNameList !== undefined ? channelNameList : []
                        }
                        placeholder="Channel"
                        selected={selectedChannel}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="form-orderTypeId">
                      <Form.Label className="fw-bold">Date:</Form.Label>
                      <Form.Control
                        placeholder={"date?"}
                        value={d}
                        type="text"
                        name="dateOfIssue"
                        onChange={(event) => editField(event)}
                        autoComplete="name"
                        required="required"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <hr className="my-3" />

                <Row className="mb-2">
                  <Col>
                    <Form.Group controlId="form-productId">
                      <Form.Label className="fw-bold">Product Name:</Form.Label>
                      <Typeahead
                        clearButton
                        id="basic-example"
                        name="productId"
                        label="Product Name"
                        disabled={loadingProduct ? true : false}
                        onChange={(selected) => {
                          setSelected(selected);
                          const value = selected.length > 0
                            ? { id: selected[0].id, label: selected[0].label }
                            : { id: "", label: "" };
                          const valueChannel = selectedChannel.length > 0
                            ? { id: selectedChannel[0].id, label: selectedChannel[0].label }
                            : { id: "", label: "" };
                        
                          const dataPayload = {
                            productNameId: value.id !== "" ? value.id : 0,
                            channelNameId: valueChannel.id !== "" ? valueChannel.id : 0,
                          };
                          dispatch(getCurrentStock(dataPayload))
                            .then((res) => {
                             
                              setProduct({
                                ...product,
                                id: value.id,
                                label: value.label,
                                currentStock: res.length > 0 ? res[0].currentQty : 0,
                              });
                            })
                            .catch((error) => {
                              console.error("Error fetching stock data:", error); // Debugging
                            });
                        }}
                        
                        options={products !== undefined ? products : []}
                        placeholder="Product Name"
                        selected={selected}
                      />
                    </Form.Group>
                    <Form.Group controlId="form-quantity-unitPrice">
                      <Form.Label className="fw-bold">Quantity:</Form.Label>
                      <Form.Control
                        name="quantity"
                        type="number"
                        value={product.quantity}
                        onChange={(event) => {
                          const value = event.target.value; // Get the raw input value
                          setProduct((prevState) => ({
                            ...prevState,
                            quantity: value === "" || value === "-" ? value : parseFloat(value),
                          }));
                          //console.log("Quantity Updated:", value); // Debugging
                        }}
                        placeholder="Enter quantity (negative allowed)"
                      />
                    </Form.Group>
                    <Button className="fw-bold my-3" onClick={handleAddEvent}>
                      Add Item
                    </Button>
                  </Col>
                  <Col>
                    <Form.Group controlId="form-quantity-unitPrice">
                      <Form.Label className="fw-bold">
                        Current Stock:
                      </Form.Label>
                      <Form.Control
                        name="currentStock"
                        type="number"
                        value={product.currentStock}
                        onChange={(event) => {
                          editProductField(event);
                        }}
                        placeholder="current stock will show here.."
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <StockAdjustmentItem
                  onItemizedItemEdit={onItemizedItemEdit}
                  onRowAdd={handleAddEvent}
                  onRowDel={handleRowDel}
                  currency={data.currency}
                  items={data.items}
                  switchMappingOption={false}
                  allOptionAuto={false}
                  mappingOption={{ mappingOption, setMappingOption }}
                />

                <hr className="my-4" />
                <Form.Label className="fw-bold">Remarks:</Form.Label>
                <Form.Control
                  placeholder="Keep some notes!"
                  name="notes"
                  as="textarea"
                  className="my-2"
                  value={data.notes}
                  onChange={(event) => {
                    setData({
                      ...data,
                      notes: event.target.value,
                    });
                  }}
                  rows={1}
                />
                <br/>
                <div>
                  <Button
                    //variant="primary"
                    variant="success" // Makes the button green
                    size="lg"
                    type="button"
                    onClick={openModal}
                  >
                    {loadingCreate ? (
                      <CircularProgress size="1rem" color="error" />
                    ) : (
                      ""
                    )}{" "}
                    Save
                  </Button>{" "}
                  <Button
                    variant="secondary"
                    size="lg"
                    type="button"
                    onClick={() => window.location.reload()}
                    style={{ marginLeft: "10px" }} // Optional: adds spacing between buttons
                  >
                    Refresh
                  </Button>
                </div>
              </Card>
            </Col>
          </Row>
        </Form>
        <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />
        <SuccessPopup openPopup={openPopup2} setOpenPopup={setOpenPopup2} />
      </Container>
    </div>
  );
};
export default CreatestockAdjustment;
