export const PAYMENT_MODE_LIST_REQUEST = "PAYMENT_MODE_LIST_REQUEST";
export const PAYMENT_MODE_LIST_SUCCESS = "PAYMENT_MODE_LIST_SUCCESS";
export const PAYMENT_MODE_LIST_FAIL = "PAYMENT_MODE_LIST_FAIL";

export const INVOICE_CHEQUE_KEY_VALUE_LIST_REQUEST =
  "INVOICE_CHEQUE_KEY_VALUE_LIST_REQUEST";
export const INVOICE_CHEQUE_KEY_VALUE_LIST_SUCCESS =
  "INVOICE_CHEQUE_KEY_VALUE_LIST_SUCCESS";
export const INVOICE_CHEQUE_KEY_VALUE_LIST_FAIL =
  "INVOICE_CHEQUE_KEY_VALUE_LIST_FAIL";
export const INVOICE_CHEQUE_KEY_VALUE_LIST_RESET =
  "INVOICE_CHEQUE_KEY_VALUE_LIST_RESET";

export const INVOICE_DETAILS_REQUEST = "INVOICE_DETAILS_REQUEST";
export const INVOICE_DETAILS_SUCCESS = "INVOICE_DETAILS_SUCCESS";
export const INVOICE_DETAILS_FAIL = "INVOICE_DETAILS_FAIL";
export const INVOICE_DETAILS_RESET = "INVOICE_DETAILS_RESET";

export const PAYMENT_HISTORY_DETAILS_REQUEST =
  "PAYMENT_HISTORY_DETAILS_REQUEST";
export const PAYMENT_HISTORY_DETAILS_SUCCESS =
  "PAYMENT_HISTORY_DETAILS_SUCCESS";
export const PAYMENT_HISTORY_DETAILS_FAIL = "PAYMENT_HISTORY_DETAILS_FAIL";
export const PAYMENT_HISTORY_DETAILS_RESET = "PAYMENT_HISTORY_DETAILS_RESET";

export const PAYMENT_LIST_REQUEST = "PAYMENT_LIST_REQUEST";
export const PAYMENT_LIST_SUCCESS = "PAYMENT_LIST_SUCCESS";
export const PAYMENT_LIST_FAIL = "PAYMENT_LIST_FAIL";
export const PAYMENT_LIST_RESET = "PAYMENT_LIST_RESET";

export const PAYMENT_CREATE_REQUEST = "PAYMENT_CREATE_REQUEST";
export const PAYMENT_CREATE_SUCCESS = "PAYMENT_CREATE_SUCCESS";
export const PAYMENT_CREATE_FAIL = "PAYMENT_CREATE_FAIL";
export const PAYMENT_CREATE_RESET = "PAYMENT_CREATE_RESET";

export const PAYMENT_UPDATE_REQUEST = "PAYMENT_UPDATE_REQUEST";
export const PAYMENT_UPDATE_SUCCESS = "PAYMENT_UPDATE_SUCCESS";
export const PAYMENT_UPDATE_FAIL = "PAYMENT_UPDATE_FAIL";
export const PAYMENT_UPDATE_RESET = "PAYMENT_UPDATE_RESET";

export const PAYMENT_DETAILS_REQUEST = "PAYMENT_DETAILS_REQUEST";
export const PAYMENT_DETAILS_SUCCESS = "PAYMENT_DETAILS_SUCCESS";
export const PAYMENT_DETAILS_FAIL = "PAYMENT_DETAILS_FAIL";
export const PAYMENT_DETAILS_RESET = "PAYMENT_DETAILS_RESET";
