import Axios from "axios";
import { API_URL } from "../constants/apiConstants";
import {
  LOCATION_CREATE_FAIL,
  LOCATION_CREATE_REQUEST,
  LOCATION_CREATE_SUCCESS,
  
  LOCATION_DELETE_FAIL,
  LOCATION_DELETE_REQUEST,
  LOCATION_DELETE_SUCCESS,

  LOCATION_DETAILS_FAIL,
  LOCATION_DETAILS_REQUEST,
  LOCATION_DETAILS_SUCCESS,

  LOCATION_DROPDOWN_LIST_FAIL,
  LOCATION_DROPDOWN_LIST_REQUEST,
  LOCATION_DROPDOWN_LIST_SUCCESS,
  
  LOCATION_LIST_FAIL,
  LOCATION_LIST_REQUEST,
  LOCATION_LIST_SUCCESS,
  
  LOCATION_UPDATE_FAIL,
  LOCATION_UPDATE_REQUEST,
  LOCATION_UPDATE_SUCCESS,
} from "../constants/locationConstants";

export const listLocationsAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: LOCATION_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/Location/GetLocations`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);
    dispatch({ type: LOCATION_LIST_SUCCESS, payload: data.dataObj });
  } catch (error) {
    dispatch({
      type: LOCATION_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createLocationAction = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOCATION_CREATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "post",

      url: `${API_URL}/Location/AddLocation`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    var finalGotData = await Axios(config)
      .then(function (response) {
        dispatch({
          type: LOCATION_CREATE_SUCCESS,
          payload: response.data.dataObj,
        });
        return response.data;
      })
      .catch(function (error) {
        dispatch({
          type: LOCATION_CREATE_FAIL,
          payload: error.response.data.errMsg,
        });
        return error.response.data;
      });
    return finalGotData;
  } catch (error) {
    dispatch({
      type: LOCATION_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listLocationDetailsAction = (id) => async (dispatch, getState) => {

  try {
    dispatch({ type: LOCATION_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",

      url: `${API_URL}/Location/GetLocationById/${id}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await Axios(config);
 
    dispatch({ type: LOCATION_DETAILS_SUCCESS, payload: data.dataObj[0] });
  } catch (error) {
    dispatch({
      type: LOCATION_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateLocationAction = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOCATION_UPDATE_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "put",

      url: `${API_URL}/Location/LocationUpdate`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    var finalGotData = await Axios(config)
      .then(function (response) {
        dispatch({
          type: LOCATION_UPDATE_SUCCESS,
          payload: response.data.dataObj,
        });
        return response.data;
      })
      .catch(function (error) {
        dispatch({
          type: LOCATION_UPDATE_FAIL,
          payload: error.response.data.errMsg,
        });
        return error.response.data;
      });

    return finalGotData;
  } catch (error) {
    dispatch({
      type: LOCATION_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteLocationAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOCATION_DELETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "delete",

      url: `${API_URL}/Location/DeleteLocation?id=${id}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await Axios(config);
    dispatch({ type: LOCATION_DELETE_SUCCESS,
      payload: id, });
    //
  } catch (error) {
    dispatch({
      type: LOCATION_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listLocationForDropdownAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: LOCATION_DROPDOWN_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/Location/GetLocations`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);
    const final = data.dataObj.map((single) => {
      return {
        id: single.supplierId,
        label: single.supplierName,
      };
    });
    dispatch({
      type: LOCATION_DROPDOWN_LIST_SUCCESS,
      payload: final.sort((a, b) => a.label.localeCompare(b.label)),
    });
  } catch (error) {
    dispatch({
      type: LOCATION_DROPDOWN_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
