import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {Toolbar,Button as MuiButton,} from "@mui/material";
import { Box, Typography, IconButton, Tooltip } from "@mui/material";
import { DataGrid, GridToolbar, GridEventListener } from "@mui/x-data-grid";
import Menu from "../../../core/Menu";
import PageHeader from "../../../core/PageHeader";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import TelegramIcon from "@mui/icons-material/Telegram";
import AddIcon from "@mui/icons-material/Add";
import SendIcon from "@mui/icons-material/Send";
import PrintIcon from "@mui/icons-material/Print";
import InventoryIcon from "@mui/icons-material/Inventory";
import VisibilityIcon from "@mui/icons-material/Visibility";

//import {deleteEmployee} from "../../../redux/actions/employeeActions";
import Popup from "../../../core/Popup";
import Notification from "../../../core/Notification";
import ConfirmDialog from "../../../core/ConfirmDialog";
import Button from "../../../core/controls/Button";
import { InfinitySpin, ThreeDots } from "react-loader-spinner";
import { Form } from "react-bootstrap";
import {
  escalateStockin,
  listStockin,
  listStockinDetails,
} from "../../../redux/actions/stockInActions";
import {
  STOCKIN_CREATE_RESET,
  STOCKIN_ESCALATION_RESET,
  STOCKIN_UPDATE_RESET,
} from "../../../redux/constants/stockInConstants";
import StockinView from "../../../core/StockinView";
import useStyles from "./StyleList";

const ListStockInApproved = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();


  const stockinList = useSelector((state) => state.stockinList);
  const { loading, error, stockin } = stockinList;

  const stockinDetails = useSelector((state) => state.stockinDetails);
  const {
    loading: loadingStockinDetails,
    error: errorStockinDetails,
    stockinsingle,
  } = stockinDetails;

  const stockinUpdate = useSelector((state) => state.stockinUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
    updatedStockin,
  } = stockinUpdate;

  const stockinCreate = useSelector((state) => state.stockinCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    createdStockin,
  } = stockinCreate;

  const stockinEscalate = useSelector((state) => state.stockinEscalate);
  const {
    loading: loadingEscalate,
    error: errorEscalate,
    success: successEscalate,
    escalatedStockin,
  } = stockinEscalate;

  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;

  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [pageSize, setPageSize] = React.useState(25);
  const [rowId, setRowId] = useState(null);
  const [rowClicked, setRowClicked] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [flag, setFlag] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState("52");
  const [dataForEscalation, setDataForEscalation] = useState([]);
  const selectedOrderRef = useRef(null);

  const generateRandom = () => {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  };

  useEffect(() => {
    if (userInfo) {
      dispatch(listStockin(selectedOrder));
      if (successUpdate) {
        setNotify({
          isOpen: true,
          message: "Updated Successfully",
          type: "success",
        });
        dispatch({ type: STOCKIN_UPDATE_RESET });
      }
      if (successCreate) {
        setNotify({
          isOpen: true,
          message: "Stockin Created.",
          type: "success",
        });

        dispatch({ type: STOCKIN_CREATE_RESET });
      }

      if (successEscalate) {
        setConfirmDialog({
          ...confirmDialog,
          isOpen: false,
        });
        setNotify({
          isOpen: true,
          message: "Escalated Successfully",
          type: "error",
        });
        setConfirmDialog({
          ...confirmDialog,
          isOpen: false,
        });
        setDataForEscalation([]);

        dispatch({ type: STOCKIN_ESCALATION_RESET });
      }
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: "/stockin/list" } },
      };
      history.push(location);
    }

    // console.log(successUpdate);
  }, [
    dispatch,
    classes,
    history,
    userInfo,
    successUpdate,
    selectedOrder,
    successEscalate,
  ]);

  const handleEvent = (params) => {
    // history.push(`/employee/${params.row.employeeId}/edit`);
    // console.log(params.row);
  };

  const buttonsBasedOnSalesOrderType = () => {
 
    if (selectedOrder === "50") {
      return (
        <Box className={classes.actions}>
          <Button
            startIcon={<AddIcon fontSize="small" />}
            text="Add New"
            color="primary"
            onClick={() => {
              history.push("/stockin/create");
          
            }}
          />
          <Button
            startIcon={<EditOutlinedIcon fontSize="small" />}
            text="Edit"
            color="secondary"
            onClick={() => {
              if (flag === true || rowId === null) {
                setOpenPopup({
                  ...openPopup,
                  title: "SELECTION ISSUE!!!",
                  subTitle: "Minimum and Maximum one row have to be slected!",
                  isOpen: true,
                });
              }
              if (rowClicked === true) {
                history.push(`/stockin/${rowId}/edit`);
              }
            }}
          />
          <Button
            startIcon={<SendIcon fontSize="small" />}
            text="ESCALATE"
            color="success"
            onClick={() => {
              if (dataForEscalation.length > 0) {
                setConfirmDialog({
                  isOpen: true,
                  title: "Are you sure that you want to escalate this data?",
                  subTitle: "This data will be sent to the operation team!",
                  onConfirm: () => {
                    dispatch(escalateStockin(dataForEscalation));
                  },
                });
              } else {
                setOpenPopup({
                  ...openPopup,
                  title: "SELECTION ISSUE!!!",
                  subTitle: "No Data is slected!",
                  isOpen: true,
                });
              }
            }}
          />
        </Box>
      );
    } else if (selectedOrder === "52") {
      return (
        <Box className={classes.actions2}>
          <Button
            startIcon={<TelegramIcon fontSize="small" />}
            text="REVERT ACTION"
            color="error"
            onClick={() => {
              // alert("clicked");
              if (flag === true || rowId === null) {
                setOpenPopup({
                  ...openPopup,
                  title: "SELECTION ISSUE!!!",
                  subTitle: "Minimum and Maximum one row have to be slected!",
                  isOpen: true,
                });
              }
              if (rowClicked === true) {
                history.push(`/stockin/${rowId}/stockinadminrevert`);
                
              }
            }}
          />
        </Box>
      );
    } else if (selectedOrder === "2") {
      return (
        <Box className={classes.actions2}>
          <Button
            startIcon={<InventoryIcon fontSize="small" />}
            text="PACKAGING"
            color="success"
            onClick={() => {
              if (flag === true || rowId === null) {
                setOpenPopup({
                  ...openPopup,
                  title: "SELECTION ISSUE!!!",
                  subTitle: "Minimum and Maximum one row have to be slected!",
                  isOpen: true,
                });
              }
              if (rowClicked === true) {
                //history.push(`/salesorderinternal/${rowId}/package`);
                history.push(`/stockin/${rowId}/stockinadminrevert`);
              }
            }}
          />
        </Box>
      );
    }
  };

  const gridBasedOnSalesOrderType = () => {
    // if (selectedOrder === "0") {
    return (
      <div className={classes.colHeader}>
        <DataGrid
          className={classes.grid}
          columnBuffer={2}
          columnThreshold={2}
          disableVirtualization
          checkboxSelection
          rows={stockin}
          columns={
            selectedOrder === "50"
              ? [...columns_part_b_O_0, ...columns_part_a]
              : selectedOrder === "52"
              ? [...columns_part_b_O_1, ...columns_part_a]
              : selectedOrder === "2"
              ? [...columns_part_a, ...columns_part_b_O_2]
              : columns_part_a
          }
          components={{ Toolbar: GridToolbar }}
          getRowId={(row) => row.siSystemId + "-" + generateRandom()}
          onRowClick={handleEvent}
          onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[25, 50, 100]}
        />
      </div>
    );
    
  };

  const onRowsSelectionHandler = (ids) => {
    if (ids.length > 1) {
      if (selectedOrder === "52" || selectedOrder === "50") {
        var newids = ids.map((num) => {
          let val = num.split("-");
          var temp = val[0];

          return temp;
        });
        const arrOfNum = newids.map((str) => {
          return Number(str);
        });
        ids = arrOfNum;
        setDataForEscalation(ids);
      }
      setRowClicked(false);
      setFlag(true);
    } else if (ids.length < 1) {
      setRowClicked(false);
      setFlag(true);
      setDataForEscalation([]);
    } else {
      ids = ids[0].split("-");
      var temp = ids[0];
      ids = [];
      ids.push(temp);
      const arrOfNum = ids.map((str) => {
        return Number(str);
      });
      ids = arrOfNum;
      const selectedRowsData = ids.map((id) =>
        stockin.find((row) => row.siSystemId === id)
      );
      setDataForEscalation([selectedRowsData[0].siSystemId]);
      setRowId(selectedRowsData[0].siSystemId);
      setRowClicked(!rowClicked);
      setFlag(false);
    }
  };
  const openModal = (id) => {
    dispatch(listStockinDetails(id)).then((res) => {
      setIsOpen(true);
    });
  };
  const closeModal = (event) => setIsOpen(false);
 const columns_part_a = [
 
  {
      field: "siSystemNo",
      headerName: "Stockin No",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "siInvoiceNo",
      headerName: "Invoice No",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "siInvoiceDate",
      headerName: "Invoice Date",
      headerAlign: "center",
      align: "center",
    },

    {
      field: "supplierName",
      headerName: "Supplier Name",
      cellClassName: "name-column--cell",
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.row.supplierName || params.row.customerName,
    },
    {
      field: "jobType",
      headerName: "Receive Type",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "siSystemId",
      headerName: "SystemId",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "sourceChannelName",
      headerName: "Channel",
      headerAlign: "center",
      align: "center",
    },

  
  ];

  const columns_part_b_O_0 = [
    {
      field: "actions",
      headerName: "EDIT",
      type: "actions",
      renderCell: (params) => {
        const { row, ...rest } = params;
        return (
          <MuiButton
            focuselementref={selectedOrderRef}
            startIcon={<EditOutlinedIcon fontSize="small" />}
            text="Edit"
            color="secondary"
            onClick={() => {
            //history.push(`/stockin/${row.siSystemId}/edit`);
              history.push(`/stockin/${rowId}/stockinadminrevert`);
            }}
          />
        );
      },
    },

    {
      field: "actions2",
      headerName: "ESCALATE",
      type: "actions",
      renderCell: (params) => {
        const { row, ...rest } = params;
        return (
          <MuiButton
            focuselementref={selectedOrderRef}
            startIcon={<SendIcon fontSize="small" />}
            text="ESCALATE"
            color="success"
            onClick={() => {
              setConfirmDialog({
                isOpen: true,
                title: "Are you sure that you want to escalate this data?",
                subTitle: "This data will be sent to the operation team!",
                onConfirm: () => {
                  dispatch(escalateStockin([row.siSystemId]));
                },
              });
            }}
          />
        );
      },
    },
    {
      field: "actions3",
      headerName: "VIEW",
      type: "actions",
      renderCell: (params) => {
        const { row, ...rest } = params;
        return (
          <MuiButton
            title="VIEW"
            focuselementref={selectedOrderRef}
            startIcon={<VisibilityIcon fontSize="small" />}
            text="VIEW"
            color="secondary"
            onClick={() => {
              openModal(row.siSystemId);
            }}
          />
        );
      },
    },
  ];

  const columns_part_b_O_1 = [
    {
      field: "actions",
      headerName: "ACTION",
      type: "actions",
      renderCell: (params) => {
        // console.log(params);
        const { row, ...rest } = params;
        return (
          <MuiButton
            title="ACTION FOR APPROVING QUANTITY"
            focuselementref={selectedOrderRef}
            startIcon={<TelegramIcon fontSize="small" />}
            text="ACTION"
            color="error"
            onClick={() => {
              //history.push(`/stockin/${row.siSystemId}/confirmQty`);
              history.push(`/stockin/${row.siSystemId}/stockinadminrevert`);
            }}
          />
        );
      },
    },
  ];

  const columns_part_b_O_2 = [
    {
      field: "actions",
      headerName: "GENERATE INVOICE",
      type: "actions",
      renderCell: (params) => {
        const { row, ...rest } = params;

        return (
          <MuiButton
            title="PACKAGING"
            focuselementref={selectedOrderRef}
            startIcon={<PrintIcon fontSize="small" />}
            text="PACKAGING"
            color="secondary"
            onClick={() => {
              //history.push(`/salesorderinternal/${row.soSystemId}/package`);
              history.push(`/stockin/${row.siSystemId}/stockinadminrevert`);
            }}
          />
        );
      },
    },
  ];

  return (
    <div>
      <Menu />
      <PageHeader
        icon={<PersonAddIcon />}
        title="Stock In Admin Action"
        subtitle="Stock Revert and Edit"
      />
      {/* {loadingDelete && <>loading..</>} */}
      {error ? (
        <div className={classes.wrapperWhite}>
          <Toolbar
            className={classes.toolBar}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img
              className="m-4"
              src={process.env.PUBLIC_URL + "/images/networkerror.png"}
              alt=""
              width="130"
              height="35"
              style={{ width: "500px", height: "auto" }}
            />
          </Toolbar>
        </div>
      ) : loading ? (
        <div
          className="text-center"
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <InfinitySpin width="200" color="#222222" />
        </div>
      ) : (
        <div className={classes.wrapperWhite}>
          <Toolbar className={classes.toolBar}>
            <div style={{ visibility: "hidden" }}>
              <Form.Select
                aria-label="Default select example"
                value={selectedOrder}
                onChange={(e) => {
                  setSelectedOrder(e.target.value);
                }}
              >
                <option value="0">Created Sales Orders</option>
                <option value="1">Escalated Sales Orders</option>
                <option value="2">Sales Orders sent for packaging</option>
              </Form.Select>
            </div>
            {buttonsBasedOnSalesOrderType()}
          </Toolbar>
          {gridBasedOnSalesOrderType()}
          {JSON.stringify(stockinsingle) !== "{}" &&
            stockinsingle !== undefined && (
              <StockinView
                showModal={isOpen}
                closeModal={closeModal}
                info={stockinsingle}
                items={stockinsingle?.stockInItemsData}
                currency={"TK"}
                subTotal={stockinsingle?.stockInBillData?.sibCalculatedAmount}
                discountAmmount={
                  stockinsingle?.stockInBillData?.sibDiscountAmouunt
                }
                total={stockinsingle?.stockInBillData?.sibFinalAmount}
              />
            )}
          <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />
          <Notification notify={notify} setNotify={setNotify} />
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
        </div>
      )}
    </div>
  );
};

export default ListStockInApproved;
