import { API_URL } from "../constants/apiConstants";
import Axios from "axios";
import {
  LC_PROFORMA_INVOICE_LIST_REQUEST,
  LC_PROFORMA_INVOICE_LIST_SUCCESS,
  LC_PROFORMA_INVOICE_LIST_FAIL,
  LC_PROFORMA_INVOICE_DETAILS_REQUEST,
  LC_PROFORMA_INVOICE_DETAILS_SUCCESS,
  LC_PROFORMA_INVOICE_DETAILS_FAIL,
  LC_PROFORMA_INVOICE_CREATE_REQUEST,
  LC_PROFORMA_INVOICE_CREATE_SUCCESS,
  LC_PROFORMA_INVOICE_CREATE_FAIL,
  LC_PROFORMA_INVOICE_UPDATE_REQUEST,
  LC_PROFORMA_INVOICE_UPDATE_SUCCESS,
  LC_PROFORMA_INVOICE_UPDATE_FAIL,
  LC_PROFORMA_INVOICE_DELETE_REQUEST,
  LC_PROFORMA_INVOICE_DELETE_SUCCESS,
  LC_PROFORMA_INVOICE_DELETE_FAIL,
  LC_LIFECYCLE_LIST_REQUEST,
  LC_LIFECYCLE_LIST_SUCCESS,
  LC_LIFECYCLE_LIST_FAIL,
  LC_PAYMENT_TERMS_LIST_REQUEST,
  LC_PAYMENT_TERMS_LIST_SUCCESS,
  LC_PAYMENT_TERMS_LIST_FAIL,
  LC_DOCUMENT_LIST_REQUEST,
  LC_DOCUMENT_LIST_SUCCESS,
  LC_DOCUMENT_LIST_FAIL,
  LC_DOCUMENT_DETAILS_REQUEST,
  LC_DOCUMENT_DETAILS_SUCCESS,
  LC_DOCUMENT_DETAILS_FAIL,
  LC_DOCUMENT_CREATE_REQUEST,
  LC_DOCUMENT_CREATE_SUCCESS,
  LC_DOCUMENT_CREATE_FAIL,
  LC_DOCUMENT_UPDATE_REQUEST,
  LC_DOCUMENT_UPDATE_SUCCESS,
  LC_DOCUMENT_UPDATE_FAIL,
  LC_DOCUMENT_DELETE_REQUEST,
  LC_DOCUMENT_DELETE_SUCCESS,
  LC_DOCUMENT_DELETE_FAIL,
} from "../constants/LcConstans";


// Proforma Invoice List
export const listProformaInvoices = () => async (dispatch, getState) => {
  try {
    dispatch({ type: LC_PROFORMA_INVOICE_LIST_REQUEST });

    const { userLogin: { userInfo } } = getState();

    const config = {
      method: "get",
      url: `${API_URL}/LCTracking/GetAllProformaInvoices`,
      headers: { Authorization: `Bearer ${userInfo.token}` },
    };

    const { data } = await Axios(config);
    //console.log('API Response:', data); // Check the API response
    dispatch({ type: LC_PROFORMA_INVOICE_LIST_SUCCESS, payload: data.dataObj });
    
  } catch (error) {
    dispatch({
      type: LC_PROFORMA_INVOICE_LIST_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};

// Proforma Invoice Details
export const getProformaInvoiceDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: LC_PROFORMA_INVOICE_DETAILS_REQUEST });

    const { userLogin: { userInfo } } = getState();

    const config = {
      method: "get",
      url: `${API_URL}/LCTracking/GetProformaInvoiceById/${id}`,
      headers: { Authorization: `Bearer ${userInfo.token}` },
    };

    const { data } = await Axios(config);
    dispatch({ type: LC_PROFORMA_INVOICE_DETAILS_SUCCESS, payload: data.dataObj });
  } catch (error) {
    dispatch({
      type: LC_PROFORMA_INVOICE_DETAILS_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};

// Proforma Invoice Create
export const createProformaInvoice = (proformaData) => async (dispatch, getState) => {
  try {
    dispatch({ type: LC_PROFORMA_INVOICE_CREATE_REQUEST });

    const { userLogin: { userInfo } } = getState();

    const config = {
      method: "post",
      url: `${API_URL}/LCTracking/CreateProformaInvoice`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(proformaData),
    };

    const { data } = await Axios(config);

    dispatch({ type: LC_PROFORMA_INVOICE_CREATE_SUCCESS, payload: data.dataObj });

    // Return data for further handling in the component
    return { data: data.dataObj, statusCode: 201 };

  } catch (error) {
    const errMsg = error.response?.data?.errMsg || error.message;
    
    dispatch({
      type: LC_PROFORMA_INVOICE_CREATE_FAIL,
      payload: errMsg,
    });

    // Return error with specific errMsg if available
    return { errMsg, statusCode: error.response?.status || 500 };
  }
};

// Proforma Invoice Update
export const updateProformaInvoice = (proformaData) => async (dispatch, getState) => {
  try {
    dispatch({ type: LC_PROFORMA_INVOICE_UPDATE_REQUEST });

    const { userLogin: { userInfo } } = getState();

    const config = {
      method: "put",
      url: `${API_URL}/LCTracking/UpdateProformaInvoice`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(proformaData),
    };

    const { data } = await Axios(config);
    dispatch({ type: LC_PROFORMA_INVOICE_UPDATE_SUCCESS, payload: data.dataObj });
  } catch (error) {
    dispatch({
      type: LC_PROFORMA_INVOICE_UPDATE_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};

// Proforma Invoice Delete
export const deleteProformaInvoice = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: LC_PROFORMA_INVOICE_DELETE_REQUEST });

    const { userLogin: { userInfo } } = getState();

    const config = {
      method: "delete",
      url: `${API_URL}/LCTracking/ProformaInvoices/${id}`,
      headers: { Authorization: `Bearer ${userInfo.token}` },
    };

    await Axios(config);
    dispatch({ type: LC_PROFORMA_INVOICE_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: LC_PROFORMA_INVOICE_DELETE_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};

// LC Lifecycle List
export const listLifecycles = () => async (dispatch, getState) => {
  try {
    dispatch({ type: LC_LIFECYCLE_LIST_REQUEST });

    const { userLogin: { userInfo } } = getState();

    const config = {
      method: "get",
      url: `${API_URL}/LCTracking/LifeCycles`,
      headers: { Authorization: `Bearer ${userInfo.token}` },
    };

    const { data } = await Axios(config);
    dispatch({ type: LC_LIFECYCLE_LIST_SUCCESS, payload: data.dataObj });
  } catch (error) {
    dispatch({
      type: LC_LIFECYCLE_LIST_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};

//Payment Terms List
export const listPaymentTerms = () => async (dispatch, getState) => {
  try {
    dispatch({ type: LC_PAYMENT_TERMS_LIST_REQUEST });

    const { userLogin: { userInfo } } = getState();
    //console.log('User Info:', userInfo); // Check user info availabili
    const config = {
      method: "get",
      url: `${API_URL}/LCTracking/GetPaymentTerms`,
      headers: { Authorization: `Bearer ${userInfo.token}` },
    };

    const { data } = await Axios(config);
    //console.log('Payment Terms API Response:', data);
    dispatch({ type: LC_PAYMENT_TERMS_LIST_SUCCESS, payload: data.dataObj });
  } catch (error) {
    //console.error('Payment Terms Fetch Error:', error);
    dispatch({
      type: LC_PAYMENT_TERMS_LIST_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};

// export const listPaymentTerms = () => async (dispatch, getState) => {
//   try {
//     dispatch({ type: LC_PAYMENT_TERMS_LIST_REQUEST });
//     const {
//       userLogin: { userInfo },
//     } = getState();

//     var config = {
//       method: "get",
//       url: `${API_URL}/LCTracking/GetPaymentTerms`,
//       headers: {
//         Authorization: `Bearer ${userInfo.token}`,
//       },
//     };
//     const { data } = await Axios(config);
//     dispatch({ type: LC_PAYMENT_TERMS_LIST_SUCCESS, payload: data.dataObj });
//   } catch (error) {
//     dispatch({
//       type: LC_PAYMENT_TERMS_LIST_FAIL,
//       payload:
//         error.response && error.response.data.message
//           ? error.response.data.message
//           : error.message,
//     });
//   }
// };

// Document/Payment Information List
export const listDocuments = () => async (dispatch, getState) => {
  try {
    dispatch({ type: LC_DOCUMENT_LIST_REQUEST });

    const { userLogin: { userInfo } } = getState();

    const config = {
      method: "get",
      url: `${API_URL}/LCTracking/GetAllLCDocumentAndPayments`,
      headers: { Authorization: `Bearer ${userInfo.token}` },
    };

    const { data } = await Axios(config);
    dispatch({ type: LC_DOCUMENT_LIST_SUCCESS, payload: data.dataObj });
  } catch (error) {
    dispatch({
      type: LC_DOCUMENT_LIST_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};

// Document/Payment Information Details
export const getDocumentDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: LC_DOCUMENT_DETAILS_REQUEST });

    const { userLogin: { userInfo } } = getState();

    const config = {
      method: "get",
      url: `${API_URL}/LCTracking/GetDocumentAndPaymentsById/${id}`,
      headers: { Authorization: `Bearer ${userInfo.token}` },
    };

    const { data } = await Axios(config);
    dispatch({ type: LC_DOCUMENT_DETAILS_SUCCESS, payload: data.dataObj });
  } catch (error) {
    dispatch({
      type: LC_DOCUMENT_DETAILS_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};

// Document/Payment Information Create
export const createDocument = (documentData) => async (dispatch, getState) => {
  try {
    dispatch({ type: LC_DOCUMENT_CREATE_REQUEST });

    const { userLogin: { userInfo } } = getState();

    const config = {
      method: "post",
      url: `${API_URL}/LCTracking/CratreLCDocAndPayments`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(documentData),
    };

    const { data } = await Axios(config);
    dispatch({ type: LC_DOCUMENT_CREATE_SUCCESS, payload: data.dataObj });
  } catch (error) {
    dispatch({
      type: LC_DOCUMENT_CREATE_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};

// Document/Payment Information Update
export const updateDocument = (documentData) => async (dispatch, getState) => {
  try {
    dispatch({ type: LC_DOCUMENT_UPDATE_REQUEST });

    const { userLogin: { userInfo } } = getState();

    const config = {
      method: "put",
      url: `${API_URL}/LCTracking/UpdateLCDocAndPayments`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(documentData),
    };

    const { data } = await Axios(config);
    dispatch({ type: LC_DOCUMENT_UPDATE_SUCCESS, payload: data.dataObj });
  } catch (error) {
    dispatch({
      type: LC_DOCUMENT_UPDATE_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};

// Document/Payment Information Delete
export const deleteDocument = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: LC_DOCUMENT_DELETE_REQUEST });

    const { userLogin: { userInfo } } = getState();

    const config = {
      method: "delete",
      url: `${API_URL}/LCTracking/DeleteDocumentAndPayments/${id}`,
      headers: { Authorization: `Bearer ${userInfo.token}` },
    };

    await Axios(config);
    dispatch({ type: LC_DOCUMENT_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: LC_DOCUMENT_DELETE_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};
