import { API_URL } from "../constants/apiConstants";
import Axios from "axios";
import {
  STOCKIN_ADJUST_FAIL,
  STOCKIN_ADJUST_REQUEST,
  STOCKIN_ADJUST_SUCCESS,
  STOCKIN_CREATE_FAIL,
  STOCKIN_CREATE_REQUEST,
  STOCKIN_CREATE_SUCCESS,
  STOCKIN_DETAILS_FAIL,
  STOCKIN_DETAILS_REQUEST,
  STOCKIN_DETAILS_SUCCESS,
  STOCKIN_ESCALATION_FAIL,
  STOCKIN_ESCALATION_REQUEST,
  STOCKIN_ESCALATION_SUCCESS,
  STOCKIN_LIST_FAIL,
  STOCKIN_LIST_REQUEST,
  STOCKIN_LIST_SUCCESS,
  STOCKIN_QUANTITY_UPDATE_FAIL,
  STOCKIN_QUANTITY_UPDATE_REQUEST,
  STOCKIN_QUANTITY_UPDATE_SUCCESS,
  STOCKIN_UPDATE_FAIL,
  STOCKIN_UPDATE_REQUEST,
  STOCKIN_UPDATE_SUCCESS,
  STOCKIN_CHALLAN_REQUEST,
  STOCKIN_CHALLAN_SUCCESS,
  STOCKIN_CHALLAN_FAIL,
  STOCKIN_DATABYID_REQUEST,
  STOCKIN_DATABYID_SUCCESS,
  STOCKIN_DATABYID_FAIL,

  STOCK_IN_ADMIN_REVERT_REQUEST,
  STOCK_IN_ADMIN_REVERT_SUCCESS,
  STOCK_IN_ADMIN_REVERT_FAIL,
} from "../constants/stockInConstants";

export const createStockin = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: STOCKIN_CREATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "post",

      url: `${API_URL}/StockIn/CreateStockInOrder`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    // await Axios(config)
    //   .then(function (response) {

    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });

    // dispatch({ type: STOCKIN_CREATE_SUCCESS, payload: data });

    var finalGotData = await Axios(config)
      .then(function (response) {
        dispatch({
          type: STOCKIN_CREATE_SUCCESS,
          payload: response.data.dataObj,
        });
        return response.data;
      })
      .catch(function (error) {
        dispatch({
          type: STOCKIN_CREATE_FAIL,
          payload: error.response.data.errMsg,
        });
        return error.response.data;
      });

    return finalGotData;
  } catch (error) {
    dispatch({
      type: STOCKIN_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const adjustStockin = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: STOCKIN_ADJUST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "post",

      url: `${API_URL}/StockIn/AdjustStock`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    var finalGotData = await Axios(config)
      .then(function (response) {
        dispatch({
          type: STOCKIN_ADJUST_SUCCESS,
          payload: response.data.dataObj,
        });
        return response.data;
      })
      .catch(function (error) {
        dispatch({
          type: STOCKIN_ADJUST_FAIL,
          payload: error.response.data.errMsg,
        });
        return error.response.data;
      });

    return finalGotData;
  } catch (error) {
    dispatch({
      type: STOCKIN_ADJUST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const listStockin = (order) => async (dispatch, getState) => {
  try {
    dispatch({ type: STOCKIN_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/StockIn/StockInOrderGridData/${order}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);

    dispatch({ type: STOCKIN_LIST_SUCCESS, payload: data.dataObj });
  } catch (error) {
    dispatch({
      type: STOCKIN_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const escalateStockin = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: STOCKIN_ESCALATION_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "put",

      url: `${API_URL}/StockIn/SubmitForStockConfirmation`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    var finalGotData = await Axios(config)
      .then(function (response) {
        dispatch({
          type: STOCKIN_ESCALATION_SUCCESS,
          payload: response.data.dataObj,
        });
        return response.data;
      })
      .catch(function (error) {
        dispatch({
          type: STOCKIN_ESCALATION_FAIL,
          payload: error.response.data.errMsg,
        });
        return error.response.data;
      });

    return finalGotData;
  } catch (error) {
    dispatch({
      type: STOCKIN_ESCALATION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listStockinDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: STOCKIN_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",

      url: `${API_URL}/StockIn/GetStockInOrderDetails/${id}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await Axios(config);

    dispatch({
      type: STOCKIN_DETAILS_SUCCESS,
      payload: data.dataObj,
    });
  } catch (error) {
    dispatch({
      type: STOCKIN_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateStockin = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: STOCKIN_UPDATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "put",

      url: `${API_URL}/StockIn/UpdateStockInOrder`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    // await Axios(config)
    //   .then(function (response) {

    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });

    // dispatch({ type: STOCKIN_CREATE_SUCCESS, payload: data });

    var finalGotData = await Axios(config)
      .then(function (response) {
        dispatch({
          type: STOCKIN_UPDATE_SUCCESS,
          payload: response.data.dataObj,
        });
        return response.data;
      })
      .catch(function (error) {
        dispatch({
          type: STOCKIN_UPDATE_FAIL,
          payload: error.response.data.errMsg,
        });
        return error.response.data;
      });

    return finalGotData;
  } catch (error) {
    dispatch({
      type: STOCKIN_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateStockinQty = (id, data) => async (dispatch, getState) => {
  try {
    dispatch({ type: STOCKIN_QUANTITY_UPDATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "put",

      url: `${API_URL}/StockIn/ConfirmReceivedStock/${id}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };
    var finalGotData = await Axios(config)
      .then(function (response) {
        dispatch({
          type: STOCKIN_QUANTITY_UPDATE_SUCCESS,
          payload: response.data.dataObj,
        });
        return response.data;
      })
      .catch(function (error) {
        dispatch({
          type: STOCKIN_QUANTITY_UPDATE_FAIL,
          payload: error.response.data.errMsg,
        });
        return error.response.data;
      });

    return finalGotData;
  } catch (error) {
    dispatch({
      type: STOCKIN_QUANTITY_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};


export const listStokInInvoiceAction = (supplier,customer) => async (dispatch, getState) => {
  try {
    dispatch({ type: STOCKIN_CHALLAN_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/StockIn/GetStockInInvoiceList/${supplier.supplierId}/${customer.customerId}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);

    dispatch({ type: STOCKIN_CHALLAN_SUCCESS, payload: data.dataObj });
  } catch (error) {
    dispatch({
      type: STOCKIN_CHALLAN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};


export const detailsStokInDataByIdAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: STOCKIN_DATABYID_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/StockIn/GetStockInChallan/${id}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    
    const { data } = await Axios(config);
    console.log('API Response:', data); // Log the entire response
    
    dispatch({ type: STOCKIN_DATABYID_SUCCESS, payload: data.dataObj });
  } catch (error) {
    dispatch({
      type: STOCKIN_DATABYID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const revertReceivedStock = (siSystemId, siConfirmedItemList) => async (dispatch, getState) => {
  try {
    dispatch({ type: STOCK_IN_ADMIN_REVERT_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      method: "put",
      url: `${API_URL}/StockIn/RevertReceivedStock/${siSystemId}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(siConfirmedItemList),
    };

    const { data } = await Axios(config);
    dispatch({
      type: STOCK_IN_ADMIN_REVERT_SUCCESS,
      payload: data.dataObj,
    });
    return data;
  } catch (error) {
    const errorMsg = error.response && error.response.data.errMsg
      ? error.response.data.errMsg
      : error.message;
    dispatch({
      type: STOCK_IN_ADMIN_REVERT_FAIL,
      payload: errorMsg,
    });
    return { error: errorMsg };
  }
};
