import React, { useCallback,useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { DataGrid, GridToolbar, GridEventListener } from "@mui/x-data-grid";

import ListIcon from "@mui/icons-material/List";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddIcon from "@mui/icons-material/Add";

import { Paper, Grid, ButtonGroup, CircularProgress } from "@mui/material"; // Paper, Grid, ButtonGroup, CircularProgress
import Datepicker from "../../core/controls/Datepicker"; // Ensure the path to Datepicker is correct
import Autocomplete from "@mui/material/Autocomplete"; // Autocomplete
import Input from "../../core/controls/Input"; // Ensure the path to Input is correct
import SearchIcon from "@mui/icons-material/Search"; // SearchIcon
import ReplayIcon from "@mui/icons-material/Replay"; // ReplayIcon
import { Form } from "react-bootstrap"; // Form from react-bootstrap

import { useJsApiLoader } from "@react-google-maps/api";
import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";
import {deleteCustomer,} from "../../redux/actions/customerActions";
import { Box, Tooltip, IconButton, Toolbar } from "@mui/material";
import Button from "../../core/controls/Button";
import Notification from "../../core/Notification";
import ConfirmDialog from "../../core/ConfirmDialog";
import Popup from "../../core/Popup";
import { mapOptions } from "../../core/MapConfiguration";
import { InfinitySpin, ThreeDots } from "react-loader-spinner";
import {CHEQUE_CREATE_RESET,CHEQUE_DELETE_RESET,CHEQUE_UPDATE_RESET,
} from "../../redux/constants/chequeConstants";
import { listPaymentByParam } from "../../redux/actions/paymentActions";
import { useStyles } from "./PaymentsStyle"
import { listCustomersForSignup } from "../../redux/actions/userActions";
import { useFormik } from "formik";
import {  PAYMENT_CREATE_RESET, PAYMENT_UPDATE_RESET,PAYMENT_LIST_RESET } from "../../redux/constants/paymentConstants";

const Payments = ({ history }) => {
  const { isLoaded } = useJsApiLoader({
    id: mapOptions.googleMapApiKey,
    googleMapsApiKey: mapOptions.googleMapApiKey,
  });
  const classes = useStyles();
  const dispatch = useDispatch();

  var date = new Date(),
  mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  day = ("0" + date.getDate()).slice(-2);

// Calculate current date and start date (3 days earlier)
const currentDate = new Date();
const startDate = new Date(currentDate);
startDate.setDate(currentDate.getDate() - 3);

// Format dates as YYYY-MM-DD
const formatDate = (date) => date.toISOString().split("T")[0];

const initialValues = {
  startDate: formatDate(startDate), // Start date is 3 days earlier
  endDate: formatDate(currentDate), // End date is the current date
  customer: {
    id: "",
    label: "",
  },
};

  const paymentList = useSelector((state) => state.paymentList);
  const { loading, error, payments } = paymentList;

  const chequeCreate = useSelector((state) => state.chequeCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
  } = chequeCreate;

  const customerDelete = useSelector((state) => state.customerDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = customerDelete;

  const chequeUpdate = useSelector((state) => state.chequeUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = chequeUpdate;

  const customerList = useSelector((state) => state.customerList);
  const {
    loading: loadingCustomer,
    error: errorCustomer,
    customers,
  } = customerList;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [pageSize, setPageSize] = React.useState(25);
  const [rowId, setRowId] = useState(null);
  const [rowClicked, setRowClicked] = useState(false);
  const [flag, setFlag] = useState(false);

  const generateRandom = () => {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  };
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      const data = {
        startDate: values.startDate,
        endDate: values.endDate,
        customerId: values.customer?.id || 0, // Use 0 if customer.id is undefined
      };
  
      // Debugging log to check the data being sent
      console.log("Dispatching listPaymentByParam with data:", data);
  
      dispatch(listPaymentByParam(null, data)); // Pass data as the second argument
    },
  });
  
  
  useEffect(() => {
    if (userInfo) {
      dispatch(listCustomersForSignup());
      //dispatch(listPayment());
     // dispatch({ type: PAYMENT_LIST_RESET });
 
      if (successUpdate) {
        setNotify({
          isOpen: true,
          message: "Updated Successfully",
          type: "success",
        });
        dispatch({ type: PAYMENT_UPDATE_RESET });
      }

      
      if (successCreate) {
        setNotify({
          isOpen: true,
          message: "Added Successfully",
          type: "success",
        });

        dispatch({ type: PAYMENT_CREATE_RESET });
      }
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: "/cheque/list" } },
      };
      history.push(location);
    }
  }, [
    dispatch,history,userInfo,successCreate,successDelete,successUpdate,
  ]);

  const handleEvent = (params) => {
    history.push(`/payment/${params.row.paymentSystemId}/edit`);
  };

  const onRowsSelectionHandler = (ids) => {
    if (ids.length > 1) {
      setRowClicked(false);
      setFlag(true);
    } else if (ids.length < 1) {
      setRowClicked(false);
      setFlag(true);
    } else {
      // console.log(ids);
      ids = ids[0].split("-");
      var temp = ids[0];
      ids = [];
      ids.push(temp);
      const arrOfNum = ids.map((str) => {
        return Number(str);
      });
      ids = arrOfNum;
      const selectedRowsData = ids.map((id) =>
        payments.find((row) => row.paymentSystemId === id)
      );
      setRowId(selectedRowsData[0].paymentSystemId);
      setRowClicked(!rowClicked);
      setFlag(false);
    }
  };

  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    dispatch(deleteCustomer(id));
    setNotify({
      isOpen: true,
      message: "Deleted Successfully",
      type: "error",
    });
    setRowClicked(false);
    setFlag(false);
  };

  const dateConverter = (date) => {
    var d = new Date(date);
    d = d.toString();
    d = d.split(" ");
    d = [d[2], d[1], d[3]].join("-");
    return d;
  };

  const columns = [
    { field: "paymentSystemId", headerName: "ID", headerAlign: "center", align: "center" },
    { field: "paymentDate", headerName: "Payment Date", headerAlign: "center", align: "center" },
    { field: "moenyRecepitNo", headerName: "Receipt No", headerAlign: "center", align: "center" },
    { 
      field: "customerName", 
      headerName: "Customer Name", 
      cellClassName: "name-column--cell", 
      headerAlign: "center", 
      align: "center" 
    },
    { field: "customerAddress", headerName: "Customer Address", headerAlign: "center", align: "center" },
    { 
      field: "receiveAmount", 
      headerName: "Received Amount", 
      valueGetter: ({ row }) => row.paymentAmount ?? row.chequeAmount, 
      headerAlign: "center", 
      align: "center" 
    },
    { 
      field: "paymentAdjustedAmount", 
      headerName: "Adjustment", 
      cellClassName: "name-column--cell", 
      headerAlign: "center", 
      align: "center" 
    },
    { 
      field: "paymentMethodName", 
      headerName: "Payment Method", 
      valueGetter: ({ row }) => row.paymentMethodName ?? row.paymentMethodName, 
      headerAlign: "center", 
      align: "center" 
    },
  ];

    const customerChange = useCallback((e, v) => {
    formik.setFieldValue("customer", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  return (
    <div>
      <Menu />
      <PageHeader icon={<ListIcon />} title="Payments" subtitle="This is the payments list" />

      {error === "Network Error" ? (
        <div className={classes.wrapperWhite}>
          <Toolbar className={classes.toolBar} style={{ display: "flex", justifyContent: "center" }}>
            <img
              className="m-4"
              src={`${process.env.PUBLIC_URL}/images/networkerror.png`}
              alt=""
              width="130"
              height="35"
              style={{ width: "500px", height: "auto" }}
            />
          </Toolbar>
        </div>
      ) : loading ? (
        <div className="text-center" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <InfinitySpin width="200" color="#6C4AB6" />
        </div>
      ) : (        
        <div className={classes.wrapperWhite}>

          {/* Toolbar Section */}
          <div className={classes.wrapperWhite}>
            <Toolbar className={classes.toolBar}>
              <Button
                text="Add New"
                size="small"
                variant="outlined"
                startIcon={<AddIcon />}
                style={{ visibility: "hidden" }}
                onClick={() => {
                  history.push("/addemployee");
                  // Add additional actions here
                }}
              />
              <div className={classes.actions}>
                <Button
                  startIcon={<AddIcon fontSize="small" />}
                  text="Add New"
                  color="success"
                  onClick={() => {
                    history.push("/payment/create");
                  }}
                  style={{ marginRight: "4px" }}
                />
                <Button
                  startIcon={<EditOutlinedIcon fontSize="small" />}
                  text="Edit"
                  color="secondary"
                  onClick={() => {
                    if (flag === true || rowId === null) {
                      setOpenPopup({
                        ...openPopup,
                        title: "SELECTION ISSUE!!!",
                        subTitle: "Minimum and Maximum one row have to be selected!",
                        isOpen: true,
                      });
                    }
                    if (rowClicked === true) {
                      history.push(`/payment/${rowId}/edit`);
                    }
                  }}
                />
              </div>
            </Toolbar>
          </div>

          {/* Filter Section */}
          <div style={{ marginBottom: "40px" }}> {/* Add spacing below the filter section */}
  <Grid container justifyContent="center"> {/* Centers the entire filter section */}
    <Grid item xs={12} md={10} lg={8}> {/* Adjust width for responsiveness */}
      <Paper className={`${classes.pageContent2} paperdesign1`} style={{ padding: "20px" }}>
        <Form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} alignItems="center">
            {/* Start Date */}
            <Grid item xs={12} sm={3}>
              <Datepicker
                label="Start Date"
                name="startDate"
                value={formik.values.startDate}
                onChange={formik.handleChange}
                fullWidth
              />
            </Grid>
            {/* End Date */}
            <Grid item xs={12} sm={3}>
              <Datepicker
                label="End Date"
                name="endDate"
                value={formik.values.endDate}
                onChange={formik.handleChange}
                fullWidth
              />
            </Grid>
            {/* Customer Name */}
            <Grid item xs={12} sm={3}>
              <Autocomplete
                size="small"
                disabled={loadingCustomer}
                options={customers || []}
                value={formik.values.customer}
                onChange={customerChange}
                getOptionLabel={(option) => option.label || ""}
                renderInput={(params) => (
                  <Input
                    {...params}
                    label="Customer Name"
                    error={
                      formik.touched.customer && formik.errors.customer?.id
                    }
                    fullWidth
                  />
                )}
              />
            </Grid>
            {/* Search Button */}
            <Grid item xs={12} sm={3}>
              <Button
                text="Search"
                startIcon={
                  loading ? (
                    <CircularProgress size="1rem" color="inherit" />
                  ) : (
                    <SearchIcon />
                  )
                }
                type="submit"
                fullWidth // Ensures the button fills the column width
              />
            </Grid>
          </Grid>
        </Form>
      </Paper>
    </Grid>
  </Grid>
</div>


         {/* DataGrid Section */}
          <div className={classes.colHeader}>
            <DataGrid
              className={classes.grid}
              // experimentalFeatures={{ columnGrouping: true }}
              title={"Hello"}
              columnBuffer={2}
              columnThreshold={2}
              disableVirtualization
              checkboxSelection
              rows={payments || []} // Pass payments or an empty array
              columns={columns}
              components={{ Toolbar: GridToolbar }}
              getRowId={(row) => row.paymentSystemId + "-" + generateRandom()}
              onRowClick={handleEvent}
              onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[25, 50, 100]}
              // columnGroupingModel={columnGroupingModel}
            />
          </div>

        {/* Notifications & Popups */}
          <Notification notify={notify} setNotify={setNotify} />
          <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
          <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />
        </div>
      )}
    </div>
  );
};

export default withRouter(Payments);
