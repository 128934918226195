// Importing React and necessary hooks
import React, { useEffect, useState } from "react";
// Importing Redux hooks for state management
import { useSelector, useDispatch } from "react-redux";
// Importing HOC for route handling
import { withRouter } from "react-router-dom";
// Importing MUI DataGrid and components
import { DataGrid } from "@mui/x-data-grid";
// Importing MUI components for UI structure and icons
import { Box, Typography, Stack, CircularProgress, Divider } from "@mui/material";
import ListIcon from "@mui/icons-material/List";
// Importing custom components
import CustomToolbar from "./CustomToolbar";
import Notification from "../../core/Notification";
import ConfirmDialog from "../../core/ConfirmDialog";
import Popup from "../../core/Popup";
import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";
// Importing Redux actions and constants
import { listProformaInvoices } from "../../redux/actions/LcActions";
import { LC_PROFORMA_INVOICE_UPDATE_RESET } from "../../redux/constants/LcConstans";
// Importing styling for the component
import { useStyles } from "./lcStyle"

// PiLists component definition
const PiLists = ({ history }) => {
  const classes = useStyles(); // Custom styles hook
  const dispatch = useDispatch(); // Redux dispatch function

  // Retrieving state from Redux store
  const proformaInvoiceListState = useSelector((state) => state.proformaInvoiceListState);
  const { loading, error, proformaInvoices } = proformaInvoiceListState;
  const { userInfo } = useSelector((state) => state.userLogin);

  // Local state for managing pagination and selections
  const [pageSize, setPageSize] = useState(25);
  const [selectedRows, setSelectedRows] = useState([]); // State for selected rows
  const [notify, setNotify] = useState({ isOpen: false, message: "", type: "" });
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: "", subTitle: "" });
  const [openPopup, setOpenPopup] = useState({ isOpen: false, title: "", subTitle: "" });
  
  // Fetching data on component mount
  useEffect(() => {
    if (userInfo) {
      dispatch(listProformaInvoices());
      dispatch({ type: LC_PROFORMA_INVOICE_UPDATE_RESET });
    }
  }, [dispatch, userInfo]);

  // Handler for editing selected row
  const handleEdit = () => {
    if (selectedRows.length === 1) {
      const selectedPiId = selectedRows[0]; // Get the ID of the selected PI
      setSelectedRows([]); // Clear selected rows to avoid conflicts
      history.push(`/lctracking/${selectedPiId}/updatepi`); // Redirect to UpdatePI with piSystemId
    } else {
      setOpenPopup({
        isOpen: true,
        title: "Selection Error",
        subTitle: "Please select a single row to edit",
      });
    }
  };

  // Handler for deleting selected rows
  const handleDelete = () => {
    if (selectedRows.length > 0) {
      setConfirmDialog({
        isOpen: true,
        title: "Are you sure to delete selected item(s)?",
        subTitle: "You can't undo this action",
        onConfirm: () => {
          setNotify({ isOpen: true, message: "Deleted Successfully", type: "error" });
          setSelectedRows([]); // Clear selection after delete
        },
      });
    }
  };

  // DataGrid column definitions
  const columns = [
    { field: "piSystemId", headerName: "PI System Id", flex: 1, headerAlign: "center", align: "center" },
    { field: "piNumber", headerName: "PI Number", flex: 1, headerAlign: "center", align: "center" },
    { field: "piDate", headerName: "PI Date", flex: 1, headerAlign: "center", align: "center" },
    { field: "piCustmomerId", headerName: "Customer Id", flex: 1, headerAlign: "center", align: "center" },
    { field: "customerName", headerName: "Customer Name", flex: 1, headerAlign: "center", align: "center" },
    { field: "customerAddress", headerName: "Customer Address", flex: 2, headerAlign: "center", align: "center" },
  ];

  return (
    <Box>
      {/* Menu and page header */}
      <Menu />
      <PageHeader icon={<ListIcon />} title="PI List" subtitle="To create, edit, and escalate" />

      {/* Conditional rendering based on loading/error states */}
      {loading ? (
        <Box display="flex" justifyContent="center" p={5}>
          <CircularProgress color="primary" />
        </Box>
      ) : error ? (
        <Box display="flex" justifyContent="center" p={5}>
          <Typography variant="h6" color="error">Error loading data</Typography>
        </Box>
      ) : (
        <Box m={2} p={2} bgcolor="background.paper" borderRadius={2} boxShadow={3}>
          {/* Divider above the DataGrid */}
          <Divider sx={{ mb: 2 }} />

          <DataGrid
            rows={proformaInvoices}
            columns={columns}
            components={{
              Toolbar: CustomToolbar, // Use CustomToolbar as the toolbar
            }}
            componentsProps={{
              toolbar: {
                onAdd: () => history.push("/lctracking/createpi"),
                onEdit: handleEdit,
                onDelete: handleDelete,
                disableEdit: selectedRows.length !== 1,
                disableDelete: selectedRows.length === 0,
              },
            }}
            getRowId={(row) => row.piSystemId}
            checkboxSelection
            onSelectionModelChange={(newSelection) => setSelectedRows(newSelection)}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 20, 100]}
            autoHeight
            sx={{
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#4a4a4a", // Deep gray background color
                color: "#ffffff", // White font color
                fontWeight: "bold",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "bold",
              },
            }}
          />
          {/* Notification and dialog components */}
          <Notification notify={notify} setNotify={setNotify} />
          <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
          <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />
        </Box>
      )}
    </Box>
  );
};

// Exporting PiLists component wrapped with withRouter for route handling
export default withRouter(PiLists);
