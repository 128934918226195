import React, { useCallback, useEffect, useState } from "react";
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
} from "@mui/material";
import { Form as Form1 } from "react-bootstrap";

import CircularProgress from "@mui/material/CircularProgress";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import DeleteIcon from "@mui/icons-material/Delete";

import { useForm, Form } from "../../core/useForm";
import Input from "../../core/controls/Input";
import RadioGroup from "../../core/controls/RadioGroup";
import Select from "../../core/controls/Select";
import Checkbox from "../../core/controls/Checkbox";
import Datepicker from "../../core/controls/Datepicker";
import Button from "../../core/controls/Button";
import { withRouter } from "react-router-dom";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { updateBatch } from "../../redux/actions/BatchActions";
import { useDispatch } from "react-redux";
import { listPackTypesForInvoice } from "../../redux/actions/invoiceActions";
import { Typeahead } from "react-bootstrap-typeahead";

const statusItems = [
  { id: "1", title: "Active" },
  { id: "2", title: "Inactive" },
  { id: "3", title: "Pause" },
];

const getDepartmentCollection = [
  { id: "1", title: "Development" },
  { id: "2", title: "Marketing" },
  { id: "3", title: "Accounting" },
  { id: "4", title: "HR" },
];

var date = new Date(),
  mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  day = ("0" + date.getDate()).slice(-2);
const initialValues = {
  productId: "",
  packSize: "",
  packQuantity: "",
  packRemarks: "",
  pack: {
    id: "",
    label: "",
  },
};

const EditMapForStockin = ({
  history,
  data,
  setData,
  openPopup,
  setOpenPopup,
  productId,
  packId,
  handlePackDel,
}) => {
  const dispatch = useDispatch();
  const batchUpdate = useSelector((state) => state.batchUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = batchUpdate;

  const invoicePackTypeList = useSelector((state) => state.invoicePackTypeList);
  const {
    loading: loadingPackType,
    error: errorPackType,
    packTypes,
  } = invoicePackTypeList;

  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;

  const [selectedPackType, setSelectedPackType] = useState([]);

  // const formik = useFormik({
  //   initialValues,
  //   onSubmit: (values, action) => {
  //     // console.log("hello");
  //     // console.log(data);
  //     const filteredProduct = data.items.filter((singleData) => {
  //       return singleData.productId === values.productId;
  //     });
  //     console.log("filteredProduct");
  //     console.log(filteredProduct);
  //     let sidPacks = filteredProduct[0].sidPacks;

  //     let updatedPack = {
  //       sipPackTypeId: values.pack.id,
  //       sipPackSize: values.packSize,
  //       sipPackQty: values.packQuantity,
  //       sipPackRemarks: values.packRemarks,
  //       packTypeName: values.pack.label,
  //     };

  //     console.log("hello");
  //     console.log(sidPacks);

  //     var newPacks = sidPacks.map(function (sitems, index) {
  //       // console.log("sitems");
  //       // console.log(sitems);
  //       for (var key in sitems) {
  //         if (sitems.sipPackTypeId === values.pack.id) {
  //           // console.log("Hello");
  //           sitems["sipPackTypeId"] = updatedPack.sipPackTypeId;
  //           sitems["sipPackSize"] = updatedPack.sipPackSize;
  //           sitems["sipPackQty"] = updatedPack.sipPackQty;
  //           sitems["sipPackRemarks"] = updatedPack.sipPackRemarks;
  //           sitems["packTypeName"] = updatedPack.packTypeName;
  //         }
  //       }
  //       return sitems;
  //     });

  //     console.log("newPacks");
  //     console.log(newPacks);

  //     var items = data.items.slice();
  //     var newItems = items.map(function (sitems) {
  //       // console.log("sitems");
  //       // console.log(sitems);
  //       for (var key in sitems) {
  //         if (sitems.productId === values.productId) {
  //           // console.log("Hello");
  //           sitems["sidPacks"] = newPacks;
  //         }
  //       }
  //       return sitems;
  //     });

  //     console.log("newItems");
  //     console.log(newItems);

  //     setData({
  //       ...data,
  //       items: newItems,
  //     });
  //     setOpenPopup({
  //       ...openPopup,
  //       isOpen: false,
  //     });
  //   },
  // });

  const formik = useFormik({
    initialValues,
    onSubmit: (values, action) => {
      // Filter out the product by productId
      const filteredProduct = data.items.filter((singleData) => {
        return singleData.productId === values.productId;
      });
  
      let sidPacks = filteredProduct[0].sidPacks; // Existing packs for this product
  
      // Create the updated pack object
      let updatedPack = {
        sipPackTypeId: values.pack.id,
        sipPackSize: values.packSize,
        sipPackQty: values.packQuantity,
        sipPackRemarks: values.packRemarks,
        packTypeName: values.pack.label,
      };
  
      // Update the pack if the packId matches, otherwise add a new one
      let newPacks = sidPacks.map((sitem) => {
        if (sitem.sipPackTypeId === values.pack.id) {
          return { ...sitem, ...updatedPack }; // Update existing pack
        }
        return sitem;
      });
  
      // If the pack wasn't found in sidPacks, push it as a new pack
      if (!sidPacks.some(sitem => sitem.sipPackTypeId === values.pack.id)) {
        newPacks.push(updatedPack); // Add new pack
      }
  
      // Update the items array with the new sidPacks
      let newItems = data.items.map((item) => {
        if (item.productId === values.productId) {
          item.sidPacks = newPacks; // Replace old packs with updated packs
        }
        return item;
      });
  
      // Set the updated data
      setData({
        ...data,
        items: newItems,
      });
  
      // Close the popup
      setOpenPopup({
        ...openPopup,
        isOpen: false,
      });
    },
  });
  

  useEffect(() => {
    if (userInfo) {
      dispatch(listPackTypesForInvoice());
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: "/addcustomer" } },
      };
      history.push(location);
    }
  }, [dispatch, history, userInfo]);

  const packChange = useCallback((e, v) => {
    formik.setFieldValue("pack", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  // const changeValues = () => {
  //   let filteredProduct = data.items.filter((singleData) => {
  //     return singleData.productId === productId;
  //   });

  //   let sidPacks = filteredProduct[0].sidPacks;

  //   let filteredSidPacks = sidPacks.filter((singleData) => {
  //     return singleData.sipPackTypeId === packId;
  //   });

  //   if (filteredSidPacks.length > 0) {
  //     console.log("filteredSidPacks");
  //     console.log(filteredSidPacks);
  //     formik.setFieldValue("productId", productId);
  //     formik.setFieldValue("pack", {
  //       id: filteredSidPacks[0].sipPackTypeId || "",
  //       label: filteredSidPacks[0].packTypeName || "",
  //     });
  //     formik.setFieldValue("packSize", filteredSidPacks[0].sipPackSize);
  //     formik.setFieldValue("packQuantity", filteredSidPacks[0].sipPackQty);
  //     formik.setFieldValue("packRemarks", filteredSidPacks[0].sipPackRemarks);
  //   }
  // };

  const changeValues = () => {
    let filteredProduct = data.items.filter((singleData) => {
      return singleData.productId === productId; // Find the correct product
    });
  
    // Find the pack to edit using the packId
    let sidPacks = filteredProduct[0].sidPacks;
  
    let filteredSidPacks = sidPacks.filter((singleData) => {
      return singleData.sipPackTypeId === packId; // Find the specific packId
    });
  
    if (filteredSidPacks.length > 0) {
      // Pre-fill the form values for the selected pack
      formik.setFieldValue("productId", productId);
      formik.setFieldValue("pack", {
        id: filteredSidPacks[0].sipPackTypeId || "",
        label: filteredSidPacks[0].packTypeName || "",
      });
      formik.setFieldValue("packSize", filteredSidPacks[0].sipPackSize);
      formik.setFieldValue("packQuantity", filteredSidPacks[0].sipPackQty);
      formik.setFieldValue("packRemarks", filteredSidPacks[0].sipPackRemarks);
    }
  };
  
  useEffect(() => {
    changeValues();
  }, [data, productId, packId]);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Autocomplete
            // disablePortal
            size="small"
            // disabled={loadingPackType ? true : false}
            disabled={true}
            id="combo-box-demo-channel"
            isOptionEqualToValue={useCallback(
              (option, value) => option.value === value.value
            )}
            onChange={packChange}
            value={formik.values.pack}
            options={packTypes !== undefined ? packTypes : []}
            onOpen={formik.handleBlur}
            renderInput={(params) => (
              <Input
                label="Pack Type"
                name="pack"
                error={
                  formik.errors.pack !== undefined ? formik.errors.pack.id : ""
                }
                touched={
                  formik.touched.pack !== undefined ? formik.touched.pack : ""
                }
                {...params}
                onBlur={formik.handleBlur}
              />
            )}
          />
          <Input
            label="Pack Size"
            name="packSize"
            error={formik.errors.packSize}
            touched={formik.touched.packSize}
            value={formik.values.packSize}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            label="Pack Quantity"
            name="packQuantity"
            error={formik.errors.packQuantity}
            touched={formik.touched.packQuantity}
            value={formik.values.packQuantity}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Input
            label="Remarks"
            name="packRemarks"
            multiline
            error={formik.errors.packRemarks}
            touched={formik.touched.packRemarks}
            value={formik.values.packRemarks}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Grid>

        <Grid item xs={12}>
          <div>
            <Button
              style={{ marginRight: "0.5rem" }}
              endIcon={
                loadingUpdate ? (
                  <CircularProgress size="1rem" color="error" />
                ) : (
                  <EditOutlinedIcon />
                )
              }
              type="submit"
              color="primary"
              size="large"
              text="Update"
            />

            <Button
              endIcon={
                loadingUpdate ? (
                  <CircularProgress size="1rem" color="error" />
                ) : (
                  <DeleteIcon />
                )
              }
              type="button"
              color="error"
              size="large"
              text="Delete"
              onClick={() => {
                handlePackDel(productId, packId);
              }}
            />
          </div>
        </Grid>
      </Grid>
    </Form>
  );
};

export default withRouter(EditMapForStockin);