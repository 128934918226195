// Importing React and necessary hooks
import React, { useState, useEffect } from "react";
// Importing Redux hooks for state management
import { useDispatch, useSelector } from "react-redux";
// Importing Formik for form handling
import { useFormik } from "formik";
// Importing MUI components for UI elements
import { Grid, Paper, Button as MuiButton, CircularProgress, Select, MenuItem, InputLabel, FormControl, Typography, TextField } from "@mui/material";
// Importing route handling hooks
import { useHistory, useParams } from "react-router-dom";
// Importing Redux actions for updating and fetching proforma invoice details
import { updateProformaInvoice, getProformaInvoiceDetails } from "../../redux/actions/LcActions";
// Importing MUI Autocomplete component
import Autocomplete from "@mui/material/Autocomplete";
// Importing additional UI components
import ButtonGroup from "@mui/material/ButtonGroup";
import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";
import LocationIcon from "@mui/icons-material/LocationOn";
// Importing additional Redux actions for related data fetching
import { listBank } from "../../redux/actions/chequeActions";
import { listLocationsAction } from "../../redux/actions/locationAction";
import { listPaymentTerms } from "../../redux/actions/LcActions";
import { getCustomersList } from "../../redux/actions/CurrentStockReportActions";
// Importing custom Datepicker component
import Datepicker from "../../core/controls/Datepicker";
// Importing validation schema for form
import { proformaInvoiceSchema } from "../../schemas";
// Importing styling
import { usePIStyles } from "./lcStyle";

// UpdatePI component definition
const UpdatePI = () => {
  const classes = usePIStyles(); // Custom styles hook
  const dispatch = useDispatch(); // Redux dispatch function
  const history = useHistory(); // Hook for navigation
  const { id } = useParams(); // Fetching parameter from route

  // Local state for loading indicator
  const [loading, setLoading] = useState(false);

  // Selecting data from Redux store
  const bankList = useSelector((state) => state.bankList || {});
  const locationList = useSelector((state) => state.locationList || {});
  const customerList = useSelector((state) => state.customerList || {});
  const paymentTermsListState = useSelector((state) => state.paymentTermsListState || {});
  const proformaInvoiceDetailsState = useSelector((state) => state.proformaInvoiceDetailsState || {});
  const updateStatus = useSelector((state) => state.proformaInvoiceUpdateState || {});

  // Destructuring data from selected state
  const { loading: detailsLoading, error, proformaInvoice } = proformaInvoiceDetailsState;
  const { success: updateSuccess } = updateStatus;

  // Extracting data for form options
  const banks = bankList.banks || [];
  const locations = locationList.locations || [];
  const customers = customerList.customers || [];
  const paymentTerms = paymentTermsListState.paymentTerms || [];

  // Check if all data is loaded before rendering form
  const dataLoaded = banks.length > 0 && locations.length > 0 && customers.length > 0 && paymentTerms.length > 0 && proformaInvoice;

  // Fetching required data on component mount
  useEffect(() => {
    formik.resetForm(); // Clear form data on load
    dispatch(listBank()); // Fetch bank list
    dispatch(listLocationsAction()); // Fetch location list
    dispatch(getCustomersList()); // Fetch customer list
    dispatch(listPaymentTerms()); // Fetch payment terms list
    dispatch(getProformaInvoiceDetails(id)); // Fetch proforma invoice details based on ID
  }, [dispatch, id]);

  // Redirect on successful update
  useEffect(() => {
    if (updateSuccess) {
      history.push("/lctracking/pilists");
    }
  }, [updateSuccess, history]);

  // Formik setup for form handling and validation
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      piNumber: "",
      piAmount: "",
      piCurrency: "USD",
      piConversionRate: "",
      piBeneficiaryBankId: null,
      piBranchBankLocationId: null,
      lcPaymentTermsId: null,
      piCustomerId: null,
      piDate: "",
    },
    validationSchema: proformaInvoiceSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const payload = {
        piSystemId: id,
        piCustmomerId: values.piCustomerId?.id || null,
        piNumber: values.piNumber,
        piDate: values.piDate,
        piAmount: parseFloat(values.piAmount),
        piCurrency: values.piCurrency,
        piConvertionRate: parseFloat(values.piConversionRate),
        piBenificaryBankId: values.piBeneficiaryBankId?.id || null,
        piBranchBankLocationId: values.piBranchBankLocationId?.locationId || null,
        lcPaymentTermsId: values.lcPaymentTermsId?.lcPaymentTermsId || null,
      };

      const response = await dispatch(updateProformaInvoice(payload));
      setLoading(false);

      // Handling response status
      if (response?.statusCode === 200) {
        history.push("/lctracking/pilists");
      } else if (response?.errMsg) {
        formik.setFieldError("piNumber", response.errMsg);
        //console.log("Error response from backend:", response.errMsg);
      } else {
        formik.setFieldError("piNumber", "An unexpected error occurred. Please try again.");
       // console.log("Unexpected error occurred during update.");
      }
    },
  });

  // Populating form fields once data is loaded
  useEffect(() => {
    if (dataLoaded) {
      const date = new Date(proformaInvoice.piDate);
      const getPiDate = [
        date.getFullYear(),
        ("0" + (date.getMonth() + 1)).slice(-2),
        ("0" + date.getDate()).slice(-2),
      ].join("-");

      // Finding selected values based on fetched data
      const selectedPaymentTerm = paymentTerms.find((term) => term.lcPaymentTermsId === proformaInvoice.lcPaymentTermsId) || null;
      const selectedBranchLocation = locations.find((location) => location.locationId === proformaInvoice.piBranchBankLocationId) || null;
      const selectedCustomer = customers.find((customer) => customer.id === proformaInvoice.piCustmomerId) || null;
      const selectedBank = banks.find((bank) => bank.id === proformaInvoice.piBenificaryBankId) || null;

      formik.setValues({
        piNumber: proformaInvoice.piNumber || "",
        piAmount: proformaInvoice.piAmount || "",
        piCurrency: proformaInvoice.piCurrency || "USD",
        piConversionRate: proformaInvoice.piConvertionRate || "",
        piBeneficiaryBankId: selectedBank,
        piBranchBankLocationId: selectedBranchLocation,
        lcPaymentTermsId: selectedPaymentTerm,
        piCustomerId: selectedCustomer,
        piDate: getPiDate,
      });
    }
  }, [dataLoaded, proformaInvoice, banks, locations, paymentTerms, customers]);

  return (
    <div>
      {/* Menu and Page Header */}
      <Menu />
      <PageHeader icon={<LocationIcon />} title="Update Proforma Invoice" subtitle="Edit the details for the proforma invoice" />
      <Paper className={classes.pageContent}>
        {/* Loading and error handling */}
        {detailsLoading || !dataLoaded ? (
          <CircularProgress />
        ) : error ? (
          <Typography color="error">Error loading invoice details</Typography>
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
              {/* Left Column */}
              <Grid item xs={12} md={6}>
                {/* PI Number input field */}
                <TextField
                  fullWidth
                  label="PI Number"
                  name="piNumber"
                  value={formik.values.piNumber}
                  onChange={formik.handleChange}
                  sx={{ mb: 3 }}
                  error={formik.touched.piNumber && Boolean(formik.errors.piNumber)}
                  helperText={formik.touched.piNumber ? formik.errors.piNumber : ""}
                />
                {/* Datepicker for PI Date */}
                <Datepicker
                  fullWidth
                  label="PI Date"
                  name="piDate"
                  value={formik.values.piDate}
                  onChange={formik.handleChange}
                  sx={{ mb: 3 }}
                />
                {/* PI Amount input field */}
                <TextField
                  label="PI Amount"
                  name="piAmount"
                  value={formik.values.piAmount}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  fullWidth
                  sx={{ mb: 3 }}
                  error={formik.touched.piAmount && Boolean(formik.errors.piAmount)}
                  helperText={formik.touched.piAmount ? formik.errors.piAmount : ""}
                />
                {/* Currency selection dropdown */}
                <FormControl fullWidth>
                  <InputLabel>Currency</InputLabel>
                  <Select
                    name="piCurrency"
                    value={formik.values.piCurrency}
                    onChange={formik.handleChange}
                    sx={{ mb: 3 }}
                  >
                    <MenuItem value="USD">USD</MenuItem>
                    <MenuItem value="BDT">BDT</MenuItem>
                  </Select>
                </FormControl>
                {/* Conversion Rate input field */}
                <TextField
                  fullWidth
                  label="Conversion Rate"
                  name="piConversionRate"
                  value={formik.values.piConversionRate}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  sx={{ mb: 3 }}
                  error={formik.touched.piConversionRate && Boolean(formik.errors.piConversionRate)}
                  helperText={formik.touched.piConversionRate ? formik.errors.piConversionRate : ""}
                />
              </Grid>

              {/* Right Column */}
              <Grid item xs={12} md={6}>
                {/* Payment Terms autocomplete */}
                <Autocomplete
                  fullWidth
                  id="lcPaymentTermsId"
                  name="lcPaymentTermsId"
                  value={formik.values.lcPaymentTermsId}
                  onChange={(event, value) => formik.setFieldValue("lcPaymentTermsId", value || null)}
                  options={paymentTerms}
                  sx={{ mb: 3 }}
                  getOptionLabel={(option) => option.lcPaymetTerms || ""}
                  isOptionEqualToValue={(option, value) => option.lcPaymentTermsId === value?.lcPaymentTermsId}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Payment Terms"
                      error={formik.touched.lcPaymentTermsId && Boolean(formik.errors.lcPaymentTermsId)}
                      helperText={formik.touched.lcPaymentTermsId ? formik.errors.lcPaymentTermsId : ""}
                    />
                  )}
                />
                {/* Customer autocomplete */}
                <Autocomplete
                  fullWidth
                  id="piCustomerId"
                  name="piCustomerId"
                  value={formik.values.piCustomerId}
                  onChange={(event, value) => formik.setFieldValue("piCustomerId", value || null)}
                  options={customers}
                  sx={{ mb: 3 }}
                  getOptionLabel={(option) => option.label || option.customerName || ""}
                  isOptionEqualToValue={(option, value) => option.id === value?.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Customer"
                      error={formik.touched.piCustomerId && Boolean(formik.errors.piCustomerId)}
                      helperText={formik.touched.piCustomerId ? formik.errors.piCustomerId : ""}
                    />
                  )}
                />
                {/* Beneficiary Bank autocomplete */}
                <Autocomplete
                  fullWidth
                  id="piBeneficiaryBankId"
                  name="piBeneficiaryBankId"
                  value={formik.values.piBeneficiaryBankId}
                  onChange={(event, value) => formik.setFieldValue("piBeneficiaryBankId", value || null)}
                  options={banks}
                  sx={{ mb: 3 }}
                  getOptionLabel={(option) => option.label || option.bankName || ""}
                  isOptionEqualToValue={(option, value) => option.id === value?.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Beneficiary Bank"
                      error={formik.touched.piBeneficiaryBankId && Boolean(formik.errors.piBeneficiaryBankId)}
                      helperText={formik.touched.piBeneficiaryBankId ? formik.errors.piBeneficiaryBankId : ""}
                    />
                  )}
                />
                {/* Branch Location autocomplete */}
                <Autocomplete
                  fullWidth
                  id="piBranchBankLocationId"
                  name="piBranchBankLocationId"
                  value={formik.values.piBranchBankLocationId}
                  onChange={(event, value) => formik.setFieldValue("piBranchBankLocationId", value || null)}
                  options={locations}
                  sx={{ mb: 3 }}
                  getOptionLabel={(option) => option.locationName || ""}
                  isOptionEqualToValue={(option, value) => option.locationId === value?.locationId}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Branch Location"
                      error={formik.touched.piBranchBankLocationId && Boolean(formik.errors.piBranchBankLocationId)}
                      helperText={formik.touched.piBranchBankLocationId ? formik.errors.piBranchBankLocationId : ""}
                    />
                  )}
                />
              </Grid>

              {/* Submit and Cancel Buttons */}
              <Grid item xs={12}>
                <ButtonGroup>
                  <MuiButton
                    size="large"
                    type="submit"
                    disabled={loading}
                    endIcon={loading ? <CircularProgress size="1rem" /> : null}
                  >
                    {loading ? "Submitting..." : "Update Proforma Invoice"}
                  </MuiButton>
                  <MuiButton
                    size="large"
                    variant="contained"
                    onClick={() => history.push("/lctracking/pilists")}
                    sx={{ ml: 2, backgroundColor: "deep violet", color: "white", "&:hover": { backgroundColor: "#8A2BE2" } }}
                  >
                    Cancel
                  </MuiButton>
                </ButtonGroup>
              </Grid>
            </Grid>
          </form>
        )}
      </Paper>
    </div>
  );
};

// Exporting the UpdatePI component
export default UpdatePI;
