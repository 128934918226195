import React, { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import {Grid,Paper,Button as MuiButton,Autocomplete,createFilterOptions,} from "@mui/material";
import { Link, withRouter } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import ReplayIcon from "@mui/icons-material/Replay";
import PublishIcon from "@mui/icons-material/Publish";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ButtonGroup from "@mui/material/ButtonGroup";
import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";
import { useForm, Form } from "../../core/useForm";
import Input from "../../core/controls/Input";
import Select from "../../core/controls/Select";
import Checkbox from "../../core/controls/Checkbox";
import Datepicker from "../../core/controls/Datepicker";
import Button from "../../core/controls/Button";
import {PaymentSchema,} from "../../schemas";
import { useEffect } from "react";
import Notification from "../../core/Notification";
import "../Customers/Customer.css";
import { Circles } from "react-loader-spinner";
import {listCustomersForSignup,listEmployeesForDropdown,} from "../../redux/actions/userActions";
import {listBank,listChequeDetails,listChequeType,} from "../../redux/actions/chequeActions";
import { CHEQUE_DETAILS_RESET,} from "../../redux/constants/chequeConstants";
import {
  createPayment,
  listChequeOnlyValue,
  listInvoiceCheckKeyValue,
  listInvoiceDetails,
  listInvoiceOnlyValue,
  listPaymentHistoryDetails,
  listPaymentMode,
} from "../../redux/actions/paymentActions";
import {
  INVOICE_DETAILS_RESET,
  PAYMENT_CREATE_RESET,
  PAYMENT_HISTORY_DETAILS_RESET,
} from "../../redux/constants/paymentConstants";
import PaymentHistoryView from "../../core/PaymentHistoryView";
import Popup from "../../core/Popup";
import { listPaymentByParam } from "../../redux/actions/paymentActions";
import {  PAYMENT_LIST_RESET } from "../../redux/constants/paymentConstants";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: useTheme().spacing(5),
    padding: useTheme().spacing(3),
  },
  adornmentText: {
    "& .MuiTypography-root": {
      color: "#f3b33d",
      fontWeight: "bolder",
      fontSize: "1.5rem",
    },
  },
  submitButtonGroup: {
    backgroundColor: "#192A53",
    color: "#fff",
    margin: useTheme().spacing(1),
    "& .MuiButtonBase-root": {
      textTransform: "none",
      color: "#fff",
      border: "2px solid #5C636A",
    },
    "&:hover": {
      backgroundColor: " #192A53",
    },
  },
}));

var date = new Date(),
  mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  day = ("0" + date.getDate()).slice(-2);

const initialValues = {
  isInvoice: true,
  employee: {
    id: "",
    label: "",
  },
  paymentReference: "0",
  paymentMode: {
    id: "",
    label: "",
  },
  invoiceCheque: {
    id: "",
    label: "",
  },
  customer: {
    id: "",
    label: "",
  },
  moneyReceiptNo: "",
  invoiceNumber: "",
  paidAmount: "",
  balanceAmount: "",
  invoiceAmount: "",
  receiveAmount: "",
  adjustedAmount: "",
  adjustedAmountShow: "",
  paymentDate: [date.getFullYear(), mnth, day].join("-"),
  paymentNote: "",
  chequeDate: [date.getFullYear(), mnth, day].join("-"),
  chequeActionDate: [date.getFullYear(), mnth, day].join("-"),
  chequeNumber: "",
  chequeAmount: "",
  bank: {
    id: "",
    label: "",
  },

  chequeStatus: {
    id: 2,
    label: "Cleared",
  },
};

const AddPayment = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [locations, setLocations] = useState([]);
  const [employeeTypes, setEmployeeTypes] = useState([]);
  const [order, setOrder] = useState("0");
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState("+");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

const [checked, setChecked] = useState(true);
// At the top level of your React component:
const customerList = useSelector((state) => state.customerList);
const invoiceChequeList = useSelector((state) => state.invoiceChequeList);
const paymentModeList = useSelector((state) => state.paymentModeList);
const employeeList = useSelector((state) => state.employeeList);
const invoiceDetailsReducer = useSelector((state) => state.invoiceDetailsReducer);
const chequeDetails = useSelector((state) => state.chequeDetails);
const paymentHistoryDetails = useSelector((state) => state.paymentHistoryDetails);
const bankList = useSelector((state) => state.bankList);
const paymentCreate = useSelector((state) => state.paymentCreate);
const userLogin = useSelector((state) => state.userLogin);

// Now destructure the data from the state slices:
const {
  loading: loadingCustomer,
  error: errorCustomer,
  customers,
} = customerList;

const {
  loading: loadingInvoiceCheque,
  error: errorInvoiceCheque,
  invoicecheques,
} = invoiceChequeList;

const {
  loading: loadingPaymentMode,
  error: errorPaymentMode,
  paymentmodes,
} = paymentModeList;

const {
  loading: loadingEmployee,
  error: errorEmployee,
  employees,
} = employeeList;

const {
  loading: loadingInvoiceDetailsReducer,
  error: errorInvoiceDetailsReducer,
  invoiceSingle,
} = invoiceDetailsReducer;

const {
  loading: loadingChequeDetails,
  error: errorChequeDetails,
  chequeSingle,
} = chequeDetails;

const {
  loading: loadingPaymentHistoryDetails,
  error: errorPaymentHistoryDetails,
  paymentHistoryList,
} = paymentHistoryDetails;

const {
  loading: loadingBanks,
  error: errorBanks,
  banks,
} = bankList;

const {
  loading: loadingCreate,
  error: errorCreate,
  success: successCreate,
  payment: createdPayment,
} = paymentCreate;

const { userInfo } = userLogin;
 
 const formik = useFormik({
  initialValues,
  validationSchema: PaymentSchema,
  onSubmit: async (values, action) => {
    try {
      //console.log("Submitting values:", values);
      const payload = {
        paymentCustomerId: values.customer.id,
        chequeAmount:
          ["0", "2", "3"].includes(values.paymentReference)
            ? null
            : values.receiveAmount,
        paymentAmount:
          ["0", "2", "3", "7"].includes(values.paymentReference)
            ? values.receiveAmount
            : null,
        paymentRelatedJobId:
          ["0"].includes(values.paymentReference)
            ? values.invoiceCheque.id
            : null,
        paymentAdjustedAmount:
          ["0", "4", "5", "6"].includes(values.paymentReference) &&
          values.adjustedAmount !== ""
            ? values.adjustedAmount
            : null,
        // paymentMethodId:
        //   ["4", "5", "6"].includes(values.paymentReference) ? 9 : values.paymentMode.id,
        paymentMethodId:
          values.paymentReference === "4"
            ? 9
            : values.paymentReference === "5"
            ? 10
            : values.paymentReference === "6"
            ? 11
            : values.paymentReference === "7"
            ? 12
            : values.paymentMode.id,
        paymentDate: values.paymentDate,
        paymentCollectedBy: values.employee.id,
        moenyRecepitNo:
          ["3", "4", "5", "6", "7"].includes(values.paymentReference)
            ? ""
            : values.moneyReceiptNo,
        paymentChequeId:
          ["0", "2", "3", "4", "5", "6", "7"].includes(values.paymentReference)
            ? null
            : values.invoiceCheque.id,
        paymentNote: values.paymentNote,
        modifiedBy: null,
        modifiedDate: null,
        chequeBeneficiaryBankId:
          ["0", "2", "4", "5", "6", "7"].includes(values.paymentReference)
            ? null
            : values.bank.id,
        chequeStatusId:
          ["0", "2", "3","4", "5", "6", "7"].includes(values.paymentReference)
            ? null
            : values.chequeStatus.id,
        chequeActionDate:
          ["0", "2", "3" ,"4", "5", "6", "7"].includes(values.paymentReference)
            ? null
            : values.chequeActionDate,
      };

      //console.log("Payload for submission:", payload);

      const result = await dispatch(createPayment(payload));

      if (result?.title === "One or more validation errors occurred.") {
        //console.log("Validation Errors from API:", result);
        action.setSubmitting(false);
      } else if (result?.errMsg) {
       // console.log("Error Message from API:", result.errMsg);

        if (result.errMsg === "Money Receipt Number Already Existed") {
          action.setFieldError("moneyReceiptNo", result.errMsg);
        }
        if (result.errMsg === "Customer Already Exist") {
          action.setFieldError("errorClientName", result.errMsg);
        } else if (result.errMsg === "Contact Number Already Exist") {
          action.setFieldError("errorClientPhone", result.errMsg);
        }
        action.setSubmitting(false);
      } else {
        if (checked) {
          action.resetForm();
          setIsOpen(false);
          setOpenPopup({
            ...openPopup,
            isOpen: false,
            title: "",
            subTitle: "",
          });
          dispatch({ type: CHEQUE_DETAILS_RESET });
          dispatch({ type: INVOICE_DETAILS_RESET });
          dispatch({ type: PAYMENT_HISTORY_DETAILS_RESET });
          setOrder("0");
        } else {
          //history.push("/payment/list");
          //handleFetchAndRedirect();
          //setChecked(true);
        }
      }
    } catch (error) {
     // console.error("Error during submission:", error);
      action.setSubmitting(false);
    }
  },
});

  // useEffect(() => {
  //   if (userInfo) {
  //     dispatch(listCustomersForSignup());
  //     dispatch(listEmployeesForDropdown());

  //     if (["1"].includes(order)) {
  //       dispatch(listInvoiceCheckKeyValue(order));
  //       dispatch(listChequeType());
  //     }
  //     dispatch(listPaymentMode(2));
  //     //dispatch(listChequeStatus());
  //     if (["1","3"].includes(order)) {
  //       dispatch(listBank());
  //     }
  //     if (successCreate && checked) {
  //       setNotify({
  //         isOpen: true,
  //         message: "Added Successfully",
  //         type: "success",
  //       });

  //       dispatch({ type: PAYMENT_CREATE_RESET });
  //     }
  //   } else {
  //     const location = {
  //       pathname: "/signin",
  //       state: { from: { pathname: "/payment/create" } },
  //     };
  //     history.push(location);
  //   }
  // }, [order, dispatch, history, userInfo, successCreate]);

  useEffect(() => {
    formik.resetForm({ values: initialValues }); // Reset all fields to default
    dispatch({ type: CHEQUE_DETAILS_RESET }); // Clear cheque-related state
    dispatch({ type: INVOICE_DETAILS_RESET }); // Clear invoice-related state
    dispatch({ type: PAYMENT_HISTORY_DETAILS_RESET }); // Clear payment history state
  }, []); // Run only once when the component mounts
  
  useEffect(() => {
    if (userInfo) {
      // Fetch initial data
      dispatch(listCustomersForSignup());
      dispatch(listEmployeesForDropdown());
  
      // Conditional fetches based on `order`
      if (["1"].includes(order)) {
        dispatch(listInvoiceCheckKeyValue(order));
        dispatch(listChequeType());
      }
      dispatch(listPaymentMode(2));
      if (["1", "3"].includes(order)) {
        dispatch(listBank());
      }
  
      // Handle successful payment creation
      if (successCreate) {
        setNotify({
          isOpen: true,
          message: "Added Successfully",
          type: "success",
        });
  
        // Use handleFetchAndRedirect for consistency
        handleFetchAndRedirect(); // Redirect to payment list and refresh data
  
        // Reset state and fields
        formik.resetForm({ values: initialValues }); // Clear form fields
        dispatch(listCustomersForSignup()); // Ensure customer list is refreshed
  
        dispatch({ type: PAYMENT_CREATE_RESET }); // Reset payment creation state
        setOrder("0"); // Reset order state
  
        setOpenPopup({
          ...openPopup,
          isOpen: false,
          title: "",
          subTitle: "",
        });
  
        dispatch({ type: CHEQUE_DETAILS_RESET });
        dispatch({ type: INVOICE_DETAILS_RESET });
        dispatch({ type: PAYMENT_HISTORY_DETAILS_RESET });
      }
    } else {
      // Redirect to login if user is not authenticated
      const location = {
        pathname: "/signin",
        state: { from: { pathname: "/payment/create" } },
      };
      history.push(location);
    }
  }, [order, dispatch, history, userInfo, successCreate]);
  
  const changeInvoiceValues = () => {
    if (JSON.stringify(invoiceSingle) !== "{}" && invoiceSingle !== undefined) {
      // con;
      formik.setFieldValue("invoiceNumber", invoiceSingle.soSystemNo || "");
      formik.setFieldValue("invoiceAmount", invoiceSingle.sobFinalBill || "");
      formik.setFieldValue("paidAmount", invoiceSingle.paidAmount);
      formik.setFieldValue("balanceAmount", invoiceSingle.balance);
      formik.setFieldValue("adjustedAmountShow", invoiceSingle.adjustedAmount);
      formik.setFieldValue("customer", {
        id: invoiceSingle.customerId || "",
        label: invoiceSingle.customerName || "",
      });
    }
  };

  useEffect(() => {
    changeInvoiceValues();
  }, [invoiceSingle]);

  const changeChequeValues = () => {
    if (JSON.stringify(chequeSingle) !== "{}" && chequeSingle !== undefined) {
      var date = new Date(chequeSingle.chequeCollectionDate),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      const chequeCollectionDate = [date.getFullYear(), mnth, day].join("-");

      var date = new Date(chequeSingle.chequeDate),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      const chequeDate = [date.getFullYear(), mnth, day].join("-");

      var date = new Date(chequeSingle.chequeExpireDate),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      const chequeExpireDate = [date.getFullYear(), mnth, day].join("-");

      var date = new Date(chequeSingle.chequePlacementDate),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      const chequePlacementDate = [date.getFullYear(), mnth, day].join("-");

      var date = new Date(chequeSingle.chequeActionDate),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      const chequeActionDate = [date.getFullYear(), mnth, day].join("-");
      formik.setFieldValue("chequeCollectionDate", chequeCollectionDate || "");
      formik.setFieldValue("chequeDate", chequeDate || "");
      formik.setFieldValue("chequeExpireDate", chequeExpireDate || "");
      formik.setFieldValue("chequePlacementDate", chequePlacementDate || "");
      formik.setFieldValue("chequeActionDate", chequeActionDate || "");
      formik.setFieldValue("chequeNumber", chequeSingle.chequeNumber || "");
      formik.setFieldValue("chequeAmount", chequeSingle.chequeAmount || "");
      //added by shahed
      formik.setFieldValue("receiveAmount",chequeSingle.chequeAmount || "");
      formik.setFieldValue("chequeType", {
        id: chequeSingle.chequeMethodInfoId || "",
        label: chequeSingle.paymentMethodName || "",
      });
      formik.setFieldValue("customer", {
        id: chequeSingle.chequeCustomerId || "",
        label: chequeSingle.customerName || "",
      });
      formik.setFieldValue("bank", {
        id: chequeSingle.chequeBeneficiaryBankId || "",
        label: chequeSingle.beneficiaryBankName || "",
      });
      formik.setFieldValue("issuerBank", {
        id: chequeSingle.chequeIssuerBankId || "",
        label: chequeSingle.chequeBankName || "",
      });
      formik.setFieldValue("chequeStatus", {
        id: 2 || "",
        label: "Cleared" || "",
      });
    }
  };

  useEffect(() => {
    changeChequeValues();
  }, [chequeSingle]);
  useEffect(() => {
    formik.resetForm({ values: initialValues });
  
  }, []);
  
  const employeeChange = useCallback((e, v) => {
    formik.setFieldValue("employee", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });
  const paymentModeChange = useCallback((e, v) => {
    formik.setFieldValue("paymentMode", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const invoiceChequeChange = useCallback((e, v) => {
    formik.setFieldValue("invoiceCheque", {
      id: v?.id || "",
      label: v?.label || "",
    });

    if (order === "0") {
      dispatch(listInvoiceDetails(v?.id));
    } else if (order === "1") {
      dispatch(listChequeDetails(v?.id));
    }
  });


  // const customerChange = useCallback((e, v) => {
  //   formik.setFieldValue("customer", {
  //     id: v?.id || "",
  //     label: v?.label || "",
  //   });
  //   if (order === "0") {
  //     dispatch(listInvoiceOnlyValue(v?.id));
  //   } else if (order === "1") {
  //     dispatch(listChequeOnlyValue(v?.id));
  //   }
  // });
  
const customerChange = useCallback((e, v) => {
  const previousCustomerId = formik.values.customer?.id || null;
  if (v?.id && v.id !== previousCustomerId) {
    switch (order) {
      case "0":
        dispatch(listInvoiceOnlyValue(v.id));
        break;
      case "1":
        dispatch(listChequeOnlyValue(v.id));
        break;
      default:
        break; // No action for other orders
    }
  }
  formik.setFieldValue("customer", {
    id: v?.id || "",
    label: v?.label || "",
  });
}, [order, dispatch, formik]);

  const bankChange = useCallback((e, v) => {
    formik.setFieldValue("bank", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const invoiceChequeCheck = useCallback(
    (option, value) => option.value === value.value
  );

  const customerOptionCheck = useCallback(
    (option, value) => option.value === value.value
  );

  const bankOptionCheck = useCallback(
    (option, value) => option.value === value.value
  );

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 100,
  });

  const closeModal = (event) => {
    setIsOpen(false);
  };

  const onChange = (event) => {
    setValue(event.target.value);
  };
const handleFetchAndRedirect = () => {
  // // Define default date range (e.g., last 7 days)
  // const currentDate = new Date();
  // const startDate = new Date();
  // startDate.setDate(currentDate.getDate() - 1);

  // const formatDate = (date) => date.toISOString().split("T")[0];

  // // Prepare filter data
  // const filterData = {
  //   startDate: formik.values.startDate || formatDate(startDate), // Use formik or default
  //   endDate: formik.values.endDate || formatDate(currentDate), // Use formik or default
  //   customerId: 0, // Reset customer filter
  // };

  // // Reset and fetch updated list
 dispatch({ type: PAYMENT_LIST_RESET });
  dispatch(listPaymentByParam(null));

  // Redirect to payments list
  history.push("/payment/list");
};

  
  return (
    <div>
      <Menu />
      <PageHeader
        icon={<PersonAddIcon />}
        title="Payments"
        subtitle="Add a Payment"
      />
      <Paper className={`${classes.pageContent} paperdesign1`}>
        <Form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12} md={6}>
                   {/* {Common feild to be loaded} */}
              <Autocomplete
                disablePortal
                size="small"
                disabled={loadingEmployee ? true : false}
                id="combo-box-demo-channel"
                isOptionEqualToValue={useCallback(
                  (option, value) => option.value === value.value
                )}
                onChange={employeeChange}
                value={formik.values.employee}
                options={employees !== undefined ? employees : []}
                onOpen={formik.handleBlur}
                renderInput={(params) => (
                  <Input
                    label="Collected By"
                    name="employee"
                    error={
                      formik.errors.employee !== undefined
                        ? formik.errors.employee.id
                        : ""
                    }
                    touched={
                      formik.touched.employee !== undefined
                        ? formik.touched.employee
                        : ""
                    }
                    {...params}
                    onBlur={formik.handleBlur}
                  />
                )}
              />
              
         {/* {Common feild to be loaded} */}  
         
              <Select
                variant="outlined"
                size="small"
                label="Payment Type"
                name="paymentReference"
                error={formik.errors.paymentReference}
                touched={formik.touched.paymentReference}
                value={formik.values.paymentReference}
                onChange={(e) => {
                  formik.setFieldValue("invoiceCheque", {
                    id: "",
                    label: "",
                  });
                  formik.setFieldValue("paymentMode", {
                    id: "",
                    label: "",
                  });
                  formik.setFieldValue("customer", {
                    id: "",
                    label: "",
                  });
                  formik.setFieldValue("invoiceNumber", "");
                  formik.setFieldValue("paidAmount", "");
                  formik.setFieldValue("balanceAmount", "");
                  formik.setFieldValue("adjustedAmount", "");
                  formik.setFieldValue("adjustedAmountShow", "");
                  formik.setFieldValue("invoiceAmount", "");
                  formik.setFieldValue("receiveAmount", "");
                  formik.setFieldValue("paymentNote", "");
                  formik.setFieldValue(
                    "chequeDate",
                    [date.getFullYear(), mnth, day].join("-")
                  );
                  formik.setFieldValue(
                    "chequeActionDate",
                    [date.getFullYear(), mnth, day].join("-")
                  );
                  formik.setFieldValue("chequeNumber", "");
                  formik.setFieldValue("chequeAmount", "");
                  formik.setFieldValue("bank", {
                    id: "",
                    label: "",
                  });
                  formik.setFieldValue("chequeStatus", {
                    id: "",
                    label: "",
                  });
                  setIsOpen(false);
                  setOpenPopup({
                    ...openPopup,
                    isOpen: false,
                    title: "",
                    subTitle: "",
                  });
                  dispatch({ type: CHEQUE_DETAILS_RESET });
                  dispatch({ type: INVOICE_DETAILS_RESET });
                  dispatch({ type: PAYMENT_HISTORY_DETAILS_RESET });
                  formik.handleChange(e);
                
                  formik.setFieldValue(
                    "isInvoice",
                    String(e.target.value) === "0" ? true : false
                  );

                  setOrder(e.target.value);
                }}
                onBlur={formik.handleBlur}
                options={[
                  { id: "0", title: "Invoice" },
                  { id: "1", title: "Cheque" },
                  { id: "2", title: "Open" },
                  { id: "3", title: "Transfer" },
                  { id: "4", title: "AIT" },
                  { id: "5", title: "VAT" },
                  { id: "6", title: "Credit Adjustment" },
                  { id: "7", title: "LC Payment" },
                ]}
              />
              <Autocomplete
                  disablePortal
                  size="small"
                  disabled={loadingCustomer ? true : false}
                  id="combo-box-demo-channel"
                  isOptionEqualToValue={customerOptionCheck}
                  onChange={customerChange}
                  value={formik.values.customer}
                  options={customers !== undefined ? customers : []}
                  onOpen={formik.handleBlur}
                  filterOptions={filterOptions}
                  renderInput={(params) => (
                    <Input
                      label="Customer Name"
                      name="customer"
                      error={
                        formik.errors.customer !== undefined
                          ? formik.errors.customer.id
                          : ""
                      }
                      touched={
                        formik.touched.customer !== undefined
                          ? formik.touched.customer
                          : ""
                      }
                      {...params}
                      onBlur={formik.handleBlur}
                    />
                  )}
                />
                <Datepicker
                      label="Payment Date"
                      name="paymentDate"
                      value={formik.values.paymentDate}
                      onChange={formik.handleChange}
                    />
      
              {["0", "1", "2", "3"].includes(order) && (
                <Autocomplete
                  disablePortal
                  size="small"
                  disabled={loadingPaymentMode}
                  id="combo-box-demo-channel"
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  onChange={paymentModeChange}
                  value={formik.values.paymentMode}
                  options={paymentmodes || []}
                  onOpen={formik.handleBlur}
                  renderInput={(params) => (
                    <Input
                      label="Payment Mode"
                      name="paymentMode"
                      error={
                        formik.errors.paymentMode?.id || ""
                      }
                      touched={
                        formik.touched.paymentMode || ""
                      }
                      {...params}
                      onBlur={formik.handleBlur}
                    />
                  )}
                />
              )}
               {["0", "1"].includes(order) && (
                <Autocomplete
                  disablePortal
                  size="small"
                  disabled={loadingInvoiceCheque}
                  id="combo-box-demo-channel"
                  isOptionEqualToValue={invoiceChequeCheck}
                  onChange={invoiceChequeChange}
                  value={formik.values.invoiceCheque}
                  options={invoicecheques || []}
                  onOpen={formik.handleBlur}
                  renderInput={(params) => (
                    <Input
                      label={order === "0" ? "Invoice No" : "Cheque No"}
                      name="invoiceCheque"
                      error={
                        formik.errors.invoiceCheque?.id || ""
                      }
                      touched={formik.touched.invoiceCheque || ""}
                      {...params}
                      onBlur={formik.handleBlur}
                    />
                  )}
                />
              )}

            {["0", "1", "7", "2", "3"].includes(order) && (
                <Input
                  label="Receive Amount"
                  name="receiveAmount"
                  error={formik.errors.receiveAmount}
                  touched={formik.touched.receiveAmount}
                  value={
                    order === "1"
                      ? formik.values.chequeAmount // Use chequeAmount for order 1
                      : formik.values.receiveAmount // Use receiveAmount for other orders
                  }
                  onChange={order === "1" ? undefined : formik.handleChange} // Disable onChange for order 1
                  onBlur={order === "1" ? undefined : formik.handleBlur} // Disable onBlur for order 1
                  disabled={order === "1"} // Disable input for order 1
                />
              )}
              
            {["0", "2"].includes(order) && (
                <Input
                  label="Money Receipt No"
                  name="moneyReceiptNo"
                  error={formik.errors.moneyReceiptNo}
                  touched={formik.touched.moneyReceiptNo}
                  value={formik.values.moneyReceiptNo}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              )}
              {["1","3"].includes(order) && (
                <Autocomplete
                  disablePortal
                  size="small"
                  disabled={loadingBanks}
                  id="combo-box-demo-channel"
                  isOptionEqualToValue={bankOptionCheck}
                  onChange={bankChange}
                  value={formik.values.bank}
                  options={banks || []}
                  onOpen={formik.handleBlur}
                  renderInput={(params) => (
                    <Input
                      label="Beneficiary Bank"
                      name="bank"
                      error={formik.errors.bank?.id || ""}
                      touched={formik.touched.bank || ""}
                      {...params}
                      onBlur={formik.handleBlur}
                    />
                  )}
                />
              )}
             
              {/* {["0", "1"].includes(order) && (
                <>
                  <div>
                    <MuiButton
                      size="small"
                      startIcon={
                        loadingPaymentHistoryDetails ? (
                          <CircularProgress size="1rem" color="error" />
                        ) : null
                      }
                      onClick={() => {
                        if (formik.values.invoiceCheque?.id) {
                          dispatch(
                            listPaymentHistoryDetails(formik.values.invoiceCheque.id)
                          ).then((res) => {
                            if (res?.length > 0) {
                              setIsOpen(true);
                            } else {
                              setOpenPopup({
                                ...openPopup,
                                isOpen: true,
                                title: "EMPTY HISTORY!!",
                                subTitle: "No history found!",
                              });
                            }
                          });
                        } else {
                          setOpenPopup({
                            ...openPopup,
                            isOpen: true,
                            title: "NO INVOICE SELECTED!!",
                            subTitle: "Please select an invoice!",
                          });
                        }
                      }}
                    >
                      View
                    </MuiButton>
                  </div>

                  {!["1"].includes(order) && (
                  <Input
                    label="Adjusted Amount"
                    name="adjustedAmount"
                    error={formik.errors.adjustedAmount}
                    touched={formik.touched.adjustedAmount}
                    value={formik.values.adjustedAmount}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                )}
                </>
              )} */}

              {[ "4", "5", "6"].includes(order) && (
                <>
                  <Input
                    label="Adjusted Amount"
                    name="adjustedAmount"
                    error={formik.errors.adjustedAmount}
                    touched={formik.touched.adjustedAmount}
                    value={formik.values.adjustedAmount}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputProps={{}}
                  />
                </>
              )}
              {[ "1"].includes(order) &&
                JSON.stringify(chequeSingle) !== "{}" &&
                chequeSingle !== undefined && (
                  <>
                
                    <Datepicker
                      label="Cheque Action Date"
                      name="chequeActionDate"
                      value={formik.values.chequeActionDate}
                      onChange={formik.handleChange}
                    />
                  </>
                )}
                <Input
                  label="Payment Note"
                  name="paymentNote"
                  error={formik.errors.paymentNote}
                  touched={formik.touched.paymentNote}
                  value={formik.values.paymentNote}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  multiple
                />
            </Grid>

            <Grid item xs={12} md={6}>
              {[ "0"].includes(order) &&
                JSON.stringify(invoiceSingle) !== "{}" &&
                invoiceSingle !== undefined && (
                  <>
                    <Input
                      label="Invoice Number"
                      name="invoiceNumber"
                      error={formik.errors.invoiceNumber|| ""}
                      touched={formik.touched.invoiceNumber|| ""}
                      value={formik.values.invoiceNumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled
                    />
                    <Input
                      label="Invoice Amount"
                      name="invoiceAmount"
                      error={formik.errors.invoiceAmount}
                      touched={formik.touched.invoiceAmount}
                      value={formik.values.invoiceAmount}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled
                    />
                    <Input
                      label="paid Amount"
                      name="paidAmount"
                      error={formik.errors.paidAmount}
                      touched={formik.touched.paidAmount}
                      value={formik.values.paidAmount}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled
                    />
                    <Input
                      label="Adjusted Amount"
                      name="adjustedAmountShow"
                      error={formik.errors.adjustedAmountShow}
                      touched={formik.touched.adjustedAmountShow}
                      value={formik.values.adjustedAmountShow}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled
                    />
                    <Input
                      label="Balance Amount"
                      name="balanceAmount"
                      error={formik.errors.balanceAmount}
                      touched={formik.touched.balanceAmount}
                      value={formik.values.balanceAmount}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled
                    />
                  </>
                )}

              {[ "1"].includes(order) &&
                JSON.stringify(chequeSingle) !== "{}" &&
                chequeSingle !== undefined && (
                  <>
                    <Input
                      label="Cheque Number"
                      name="chequeNumber"
                      error={formik.errors.chequeNumber}
                      touched={formik.touched.chequeNumber}
                      value={formik.values.chequeNumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled
                    />
                    <Input
                      label="Cheque Amount"
                      name="chequeAmount"
                      error={formik.errors.chequeAmount}
                      touched={formik.touched.chequeAmount}
                      value={formik.values.chequeAmount}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled
                    />
                    <Datepicker
                      label="Cheque Date"
                      name="chequeDate"
                      value={formik.values.chequeDate}
                      onChange={formik.handleChange}
                      disabled
                    />
                  </>
                )}
                
            </Grid>

            <Grid item xs={12} md={6}>
              <Checkbox
                label="Stay in this Page"
                name="stay"
                value={checked}
                onChange={(e) => {
                  setChecked(!checked);
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <ButtonGroup className={classes.submitButtonGroup} type="submit">
                
                <MuiButton
                  endIcon={
                    loadingCreate ? (
                      <CircularProgress size="1rem" color="error" />
                    ) : (
                      <PublishIcon />
                    )
                  }
                  size="large"
                  type="submit"
                >
                  Submit
                </MuiButton>
                <MuiButton
                  size="small"
                  startIcon={<ReplayIcon />}
                  onClick={() => {
                    // console.log(formik);
                    formik.resetForm();
                    setChecked(true);
                  }}
                />
              </ButtonGroup>
              <MuiButton
                  size="small"
                  color="secondary"
                  onClick={handleFetchAndRedirect}
                >
                  Back
                </MuiButton>
            </Grid>
          </Grid>
        </Form>
        <div
          className="paperdesign2"
          style={{
            display:
            loadingEmployee
              
                ? ""
                : "none",
          }}
        >
          <div>
            <Circles
              height="70"
              width="100"
              color="#F3EC19"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              visible={true}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                color: "white",
              }}
            >
              Please wait
            </p>
          </div>
        </div>
      </Paper>
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />
      {JSON.stringify(paymentHistoryList) !== "[]" &&
        paymentHistoryList !== undefined && (
          <PaymentHistoryView
            showModal={isOpen}
            closeModal={closeModal}
            info={paymentHistoryList}
          />
        )}
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default withRouter(AddPayment);
